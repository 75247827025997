import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from '../Modal';
import { Icon, Button, Row, Col, cssVariables } from '../index';
import { t } from '../../i18n';
import { AdSelectMediaModal, MediaCard } from './index';
import { getDefaultCrop } from './utils';

const MediaPreviewModal = ({
  media,
  onClose,
  onConfirm,
  onGoBack,
  confirmButtonText,
  showGoBackButton,
  organizationId,
  campaignSettingId,
  type,
}) => {
  const [previewMedia, setPreviewMedia] = useState([...media]);
  const [showSelectModal, setShowSelectModal] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  const onReplaceMedia = () => {
    setPreviewMedia(prevState =>
      prevState.map(m => {
        if (m.id === selectedId) {
          return {
            ...m,
            asset_id: '',
            media_id: selectedMedia.id,
            source: selectedMedia.xlinkHref || selectedMedia.source,
            crop: null,
            width: selectedMedia.width,
            height: selectedMedia.height,
            name: selectedMedia.filename,
            type: selectedMedia.type,
            videoSource: selectedMedia.videoSource,
          };
        }
        return m;
      })
    );
    setShowSelectModal(false);
  };

  const onCropMedia = (crop, image) => {
    if (!crop) {
      onClearCrop(image);
      return;
    }
    setPreviewMedia(prevState =>
      prevState.map(m => {
        if (m.id === image.id) {
          return {
            ...m,
            crop: {
              aspect: crop.aspect,
              x: crop.x,
              y: crop.y,
              width: crop.width,
              height: crop.height,
            },
          };
        }
        return m;
      })
    );
  };

  const onDefaultCrop = (image, aspect) => {
    const defaultCrop = getDefaultCrop(image, aspect);
    setPreviewMedia(prevState =>
      prevState.map(m => {
        if (m.id === image.id) {
          return {
            ...m,
            crop: {
              aspect: defaultCrop.aspect,
              x: defaultCrop.x,
              y: defaultCrop.y,
              width: defaultCrop.width,
              height: defaultCrop.height,
            },
          };
        }
        return m;
      })
    );
  };

  const onClearCrop = image => {
    setPreviewMedia(prevState =>
      prevState.map(m => {
        if (m.id === image.id) {
          return {
            ...m,
            crop: null,
          };
        }
        return m;
      })
    );
  };

  return (
    <Modal
      onClose={onClose}
      size="dynamic"
      heading={
        <Row center>
          <Icon kind="crop-fit" size="24px" className="mr-12" color={cssVariables.iconSubtle} />
          {t('media.edit_media', { default: 'Edit media' })}
        </Row>
      }
    >
      <ModalBody>
        {showGoBackButton && (
          <Row className="mb-16">
            <Button kind="secondary" onClick={onGoBack} icon="arrow-left">
              {t('media.back_to_media_selection', { default: 'Back to media selection' })}
            </Button>
          </Row>
        )}
        <Row padding="l">
          {previewMedia.map(m => (
            <Col key={m.id}>
              <MediaCard
                media={m}
                onReplaceButtonClick={() => {
                  setSelectedMedia(m);
                  setSelectedId(m.id);
                  setShowSelectModal(true);
                }}
                onCropMedia={onCropMedia}
                onClearCrop={onClearCrop}
                onDefaultCrop={onDefaultCrop}
                type={type}
              />
            </Col>
          ))}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button kind="primary" onClick={() => onConfirm(previewMedia)} disabled={false}>
          {confirmButtonText}
        </Button>
        <Button kind="secondary" onClick={onClose}>
          {t('media.cancel', { default: 'Cancel' })}
        </Button>
      </ModalFooter>
      {showSelectModal && (
        <AdSelectMediaModal
          organizationId={organizationId}
          campaignSettingId={campaignSettingId}
          selectedMedia={selectedMedia}
          onSelect={setSelectedMedia}
          onConfirm={onReplaceMedia}
          confirmButtonText={t('media.replace_media', { default: 'Replace media' })}
          onClose={() => {
            setShowSelectModal(false);
          }}
        />
      )}
    </Modal>
  );
};

MediaPreviewModal.propTypes = {
  media: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onGoBack: PropTypes.func,
  confirmButtonText: PropTypes.string.isRequired,
  showGoBackButton: PropTypes.bool,
  organizationId: PropTypes.number,
  campaignSettingId: PropTypes.number,
  type: PropTypes.string,
};

export default MediaPreviewModal;
