/* eslint-disable no-trailing-spaces */
import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import Searchbox from '../organizations_dropdown/Searchbox';
import { t } from '../i18n';
import Switch from '../components/form/Switch';
import { Col, CreateElementButton, Row, Heading, Text, cssVariables, Button, Loader } from '../components';
import EmptyState from '../components/EmptyState';
import FbAdtextTile from './FbAdtextTile';

const QUERY_FB_ADTEXTS = gql`
  query GetFBAdtexts(
    $organizationId: BigInt
    $campaignSettingId: BigInt
    $fulltext: String
    $active: String
    $useDynamicFormat: Boolean
  ) {
    collection(
      identifier: "fbAdtexts"
      order: "name"
      orderDirection: "asc"
      page: 0
      limit: 1000
      organizationId: $organizationId
      campaignSettingId: $campaignSettingId
    ) {
      fbAdtexts(fulltext: $fulltext, active: $active, useDynamicFormat: $useDynamicFormat) {
        id
        name
        status
        headline
        description
        format
        creativeSource
        humanizedFormat
        preview
        fbProductSetInfo
        humanizedCreativeSource
        editLink
        previewImageUrl
      }
    }
  }
`;

const FbAdList = ({
  organizationId,
  campaignSettingId,
  createBtnProps,
  version = 0,
  setVersion,
  hasAdsToImport,
  showMenu = true,
  showItemsMenu = true,
  showItemsArrows = false,
  setHasAds,
}) => {
  const [search, setSearch] = useState('');
  const [activeOnly, setActiveOnly] = useState(false);
  const { data, loading, refetch } = useQuery(QUERY_FB_ADTEXTS, {
    variables: {
      organizationId,
      campaignSettingId,
      fulltext: search,
      active: activeOnly ? 'only_active' : '',
      use_dynamic_format: false,
    },
  });

  useEffect(() => {
    refetch();
  }, [version]);

  useEffect(() => {
    if (data?.collection?.fbAdtexts?.length && setHasAds) {
      setHasAds(true);
    }
  }, [data]);

  const clearSearch = () => {
    setActiveOnly(false);
    setSearch('');
  };

  const createAdUrl = `/organizations/${organizationId}/campaign_settings/${campaignSettingId}/fb_a_d_s/new`;
  const adTexts = data?.collection?.fbAdtexts || [];
  const emptyStateMessage = hasAdsToImport
    ? t('fb_advantage_plus.ads_overview.no_ads_found_text_with_import', {
        default: 'Start with creating your first ad. Or import existing ads below.',
      })
    : t('fb_advantage_plus.ads_overview.no_ads_found_text', { default: 'Start with creating your first ad.' });

  const showEmptyState = !adTexts.length && !loading && !activeOnly && !search;
  return (
    <div className="mb-24">
      {showMenu && !showEmptyState && (
        <Row className="mv-16" padding="xl">
          <Col shrink>
            <CreateElementButton {...createBtnProps} />
          </Col>
          <Col width="186px">
            <Searchbox
              searchValue={search}
              handleSearch={({ target }) => {
                setSearch(target.value);
              }}
              debounce={400}
              searchBoxText=""
              testId="search-ad"
            />
          </Col>
          <Col shrink className="justify-content-center">
            <Switch
              label={t('active_only', { scope: 'react.create_element_modal' })}
              onChange={e => {
                setActiveOnly(e.target.value);
              }}
              value={activeOnly}
              doNotUseInternalState
              name="data_source_active_only"
            />
          </Col>
        </Row>
      )}

      {adTexts?.map(item => (
        <FbAdtextTile
          key={item.id}
          item={item}
          refetch={refetch}
          setVersion={setVersion}
          showItemsMenu={showItemsMenu}
          showArrow={showItemsArrows}
        />
      ))}

      {loading && (
        <Row center justifyCenter className="Tile Tile-content" style={{ padding: '48px' }}>
          <Loader size="big" />
        </Row>
      )}

      {adTexts?.length === 0 && !loading && (activeOnly || search) && (
        <div className="mv-16">
          <EmptyState
            icon={'asset_group_icon'}
            emptySearch={t('fb_adtext.no_records_description', {
              default: 'None of the records match your search query. Try adjusting what you`re looking for.',
            })}
            hasBorder
          >
            <div className="mt-16">
              <Button id="clear-search" onClick={clearSearch} primary data-test-id="clear-filters-btn">
                {t('react.clear_filters')}
              </Button>
            </div>
          </EmptyState>
        </div>
      )}

      {showEmptyState && (
        <div className="mv-16">
          <EmptyState icon="a_d_text_icon" hasBorder>
            <div className="mt-24">
              <Heading tag="h2" spacing={16}>
                {t('fb_advantage_plus.ads_overview.no_ads_found_title', { default: 'No ads found' })}
              </Heading>
              <Text tag="div" className="mv-16" style={{ color: cssVariables.textMuted }}>
                {emptyStateMessage}
              </Text>
              <Button tag="a" href={createAdUrl} icon="plus" kind="primary" data-test-id="create-new-ad">
                {t('fb_advantage_plus.ads_overview.create_ad_cta', { default: 'Create new ad' })}
              </Button>
            </div>
          </EmptyState>
        </div>
      )}
    </div>
  );
};

FbAdList.propTypes = {
  organizationId: PropTypes.number.isRequired,
  campaignSettingId: PropTypes.number.isRequired,
  createBtnProps: PropTypes.object,
  emptyState: PropTypes.object,
  showMenu: PropTypes.bool,
  version: PropTypes.number,
  setVersion: PropTypes.func,
  hasAdsToImport: PropTypes.bool,
};

export default FbAdList;
