import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { cloneDeep } from 'lodash';
import { gql, useQuery, useMutation } from '@apollo/client';
import EmptyState from '../EmptyState';
import {
  Row,
  Col,
  Heading,
  Text,
  Icon,
  BetaBadge,
  Badge,
  Button,
  Loader,
  InfoBox,
  Checkbox,
  cssVariables,
} from '../index';
import { t } from '../../i18n';
import Searchbox from '../../organizations_dropdown/Searchbox';

const LIST_IMPORTABLE_ADS = gql`
  query ListImportableAds($organizationId: BigInt!, $campaignSettingId: BigInt!) {
    organization(id: $organizationId) {
      campaignSetting(id: $campaignSettingId) {
        fbImportableAds {
          name
          id
          alreadyImported
          campaignName
          imageUrl
        }
      }
    }
  }
`;

const IMPORT_FB_ADS = gql`
  mutation ImportFbAdtexts($fbAdtextSystemIds: [String!]!, $campaignSettingId: BigInt!) {
    importFbAdtexts(fbAdtextSystemIds: $fbAdtextSystemIds, campaignSettingId: $campaignSettingId) {
      importedFbAdtexts {
        name
        id
        status
      }
      errors
    }
  }
`;

const FbAdsImporter = ({ organizationId, campaignSettingId, campaignName, version, setVersion, setHasAdsToImport }) => {
  const [selectedAds, setSelectedAds] = useState([]);
  const [initiaded, setInitiated] = useState(false);
  const [search, setSearch] = useState('');
  const [data, setData] = useState('');
  const { loading, data: rawData, refetch } = useQuery(LIST_IMPORTABLE_ADS, {
    variables: { organizationId, campaignSettingId },
  });
  const [importFbAds, { data: importData, loading: importLoading, error: importError }] = useMutation(IMPORT_FB_ADS);

  useEffect(() => {
    refetch();
  }, [version]);

  useEffect(() => {
    if (!loading && !initiaded) {
      setInitiated(true);
    }
  }, [loading]);

  useEffect(() => {
    if (data) {
      setHasAdsToImport(data?.organization?.campaignSetting?.fbImportableAds?.length > 0);
    }
  }, [data]);

  // TODO - rewrite this to be a reusable hook
  useEffect(() => {
    const hasErrorFields = document.querySelectorAll('.has-error:not(.hidden)');
    if (hasErrorFields.length > 0) {
      hasErrorFields[0].scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      });
    }
  }, [importData]);

  useEffect(() => {
    const importedAdsAmount = importData?.importFbAdtexts?.importedFbAdtexts?.length || 0;
    if (importedAdsAmount) {
      const importSuccessMessage = t('fb_advantage_plus.ads_importer.ads_successfully_imported_description', {
        default: 'Successfully imported %{importedAdsAmount} ads into %{campaignName}',
        importedAdsAmount,
        campaignName,
      });

      new window.NotificationCenter().show_success(importSuccessMessage);
    }
  }, [importData]);

  useEffect(() => {
    if (rawData) {
      setData(rawData);
    }
  }, [rawData]);

  useEffect(() => {
    searchByText(search);
  }, [search]);

  const searchByText = text => {
    if (rawData) {
      const rawDataCopy = cloneDeep(rawData);
      rawDataCopy.organization.campaignSetting.fbImportableAds = rawDataCopy.organization.campaignSetting.fbImportableAds?.filter(
        item => item.name.toLowerCase().includes(text.toLowerCase())
      );

      setData(rawDataCopy);
    }
  };

  const handleSelectAd = adId => {
    if (selectedAds.includes(adId)) {
      setSelectedAds(selectedAds.filter(selectedAd => selectedAd !== adId));
    } else {
      setSelectedAds([...selectedAds, adId]);
    }
  };

  const handleImport = async () => {
    await importFbAds({
      variables: {
        fbAdtextSystemIds: selectedAds,
        campaignSettingId,
      },
    });
    setSelectedAds([]);
    setVersion(v => v + 1);
    refetch();
    setSearch('');
  };

  const ads = data?.organization?.campaignSetting?.fbImportableAds || [];
  const hasImportedAds = ads.some(ad => ad.alreadyImported);
  const importErrors = importData?.importFbAdtexts?.errors?.map(error => JSON.parse(error)) || [];
  const hideElement = rawData?.organization?.campaignSetting?.fbImportableAds.length === 0 && !loading && initiaded;

  return hideElement ? null : (
    <div className="FbAdImporter-Wrapper">
      <Row justifyBetween>
        <Col style={{ padding: '16px' }}>
          <Row center>
            <Col shrink style={{ marginRight: '12px' }}>
              <Icon kind="download" size="20px" color={cssVariables.iconSubtle} />
            </Col>
            <Col shrink style={{ marginRight: '12px' }}>
              <Heading size="2xl" spacing={0}>
                {t('fb_advantage_plus.ads_importer.import_your_existing_ads', { default: 'Import your existing ads' })}
              </Heading>
            </Col>
            <Col shrink>
              <BetaBadge text="BETA" size="small" />
            </Col>
          </Row>
          {!loading && (
            <Text tag="div" style={{ color: cssVariables.textMuted }}>
              {t('fb_advantage_plus.ads_importer.these_ads_are_suggested', {
                default: 'These ads are suggested for your campaign; you can import them now.',
              })}
            </Text>
          )}
        </Col>
        {!loading && hasImportedAds && (
          <Row justifyEnd style={{ padding: '16px' }}>
            <Col shrink>
              <Button
                kind="secondary"
                icon="reply"
                size="small"
                onClick={() => window.open('https://tally.so/r/3yyaLx', '_blank')}
              >
                {t('fb_advantage_plus.ads_importer.leave_feedback', { default: 'Leave feedback' })}
              </Button>
            </Col>
          </Row>
        )}
        {loading && (
          <Row style={{ padding: '0 16px 0 0' }} justifyEnd>
            <Col shrink>
              <Row center>
                <Col shrink>
                  <Loader size="small" />
                </Col>
                <Col shrink>
                  <Text tag="div" style={{ color: cssVariables.textSubtle }}>
                    {t('fb_advantage_plus.ads_importer.searching_for_ads', {
                      default: 'Searching for ads to import...',
                    })}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Row>
      {!!rawData && (
        <>
          <div style={{ padding: '16px 16px 0', borderTop: `1px solid ${cssVariables.interfaceOutlineBold}` }}>
            <InfoBox type="info" className="mb-16" withIcon>
              <Text>
                {t('fb_advantage_plus.ads_importer.imported_ads_info_box', {
                  default:
                    'Imported ads will be duplicates and will not be linked to the original ones. Any changes in imported ads won’t affect the original ones in Facebook.',
                })}
              </Text>
            </InfoBox>
            <Col width="250px" style={{ marginBottom: '16px' }}>
              <Searchbox
                searchValue={search}
                handleSearch={({ target }) => {
                  setSearch(target.value);
                }}
                searchBoxText={t('fb_ads_import.search', { default: 'Search...' })}
                testId="search-import-ad"
              />
            </Col>
            {!!ads.length && (
              <div
                className="FbAdImporter-Items ppcbee-scrollbar"
                style={{ paddingRight: ads.length > 8 ? '5px' : '0' }}
              >
                {importLoading && <div className="FbAdImporter-ItemsOverlay" />}
                {ads.map(ad => {
                  const error = importErrors.find(iError => iError.id === ad.id);
                  return (
                    <Row
                      data-test-id="imported-ad"
                      key={ad.id}
                      center
                      justifyBetween
                      className={cs('FbAdImporter-Item', {
                        'FbAdImporter-Item--selected': selectedAds.includes(ad.id),
                        'FbAdImporter-Item--error': error,
                        'has-error': error,
                      })}
                      onClick={() => handleSelectAd(ad.id)}
                    >
                      <Col shrink style={{ marginRight: '16px' }}>
                        <Checkbox
                          name={ad.name}
                          doNotUseInternalState
                          checked={selectedAds.includes(ad.id)}
                          onMouseUp={() => {
                            handleSelectAd(ad.id);
                          }}
                        />
                      </Col>
                      {ad?.imageUrl && (
                        <Col shrink style={{ marginRight: '12px' }}>
                          <img
                            src={ad.imageUrl}
                            style={{ width: '40px', height: '56px', objectFit: 'cover' }}
                            alt={ad.name}
                          />
                        </Col>
                      )}
                      <Col>
                        <Text
                          tag="div"
                          size="md"
                          bold
                          style={{ color: cssVariables.interactivePrimaryBold, marginBottom: '4px' }}
                        >
                          {ad.name}
                        </Text>
                        <Text tag="div" size="sm" style={{ color: cssVariables.textSubtle }}>
                          {ad.campaignName}
                        </Text>
                        {error && (
                          <Row center className="mt-12" style={{ paddignLeft: '8px' }}>
                            <Col shrink style={{ marginRight: '4px' }}>
                              <Icon kind="error-circle" size="16px" color={cssVariables.statusAttentionDefault} />
                            </Col>
                            <Col>
                              <Text style={{ color: cssVariables.statusAttentionDefault, marginTop: '1px' }}>
                                {error.message}
                              </Text>
                            </Col>
                          </Row>
                        )}
                      </Col>
                      {ad.alreadyImported && (
                        <Col shrink>
                          <Badge kind="lightGray" data-test-id="badge">
                            {t('fb_advantage_plus.ads_importer.imported', { default: 'Imported' })}
                          </Badge>
                        </Col>
                      )}
                    </Row>
                  );
                })}
              </div>
            )}
            {!ads.length && (
              <div className="FbAdImporter--no-results">
                <EmptyState icon="image_editor_no_images" data-test-id="fbImport-no-results">
                  <Heading className="mt-24" tag="h3" data-test-id="headline">
                    {t('fb_ads_import.no_result_found', {
                      default: 'No ads found',
                    })}
                  </Heading>
                  <Text className="MediaUpload-emptyDescription" data-test-id="description">
                    {t('fb_ads_import.no_result_found_description', {
                      default: 'No ads matching your search query. Try to use different keyword.',
                    })}
                  </Text>
                </EmptyState>
              </div>
            )}
            {importError && (
              <div className="mb-8">
                <InfoBox type="error" className="mt-16" withIcon>
                  <span>
                    <span style={{ fontWeight: 'bold' }}>Error</span>{' '}
                    {t('fb_advantage_plus.ads_importer.import_error', {
                      default: 'An issue occurred during the import process. Please try again.',
                    })}
                  </span>
                </InfoBox>
              </div>
            )}
          </div>
          <Row
            center
            justifyBetween
            style={{ padding: '16px', marginTop: '16px' }}
            className="FbAdImporter--bottom-panel"
          >
            <Col data-test-id="ads-selected">
              <Text tag="div" style={{ color: cssVariables.textSubtle }}>
                {`${selectedAds.length} ${t('fb_advantage_plus.ads_importer.selected_ads', {
                  default: 'ads selected',
                })}`}
              </Text>
            </Col>
            <Col shrink>
              <Row center>
                {importLoading && (
                  <Col shrink style={{ marginRight: '12px' }}>
                    <Text tag="div" style={{ color: cssVariables.textSubtle }}>
                      {t('fb_advantage_plus.ads_importer.import_in_progress', {
                        default: 'Import in progress. It may take a while depending on the number of ads and setup.',
                      })}
                    </Text>
                  </Col>
                )}
                <Col shrink>
                  <Button
                    data-test-id="import-ads"
                    kind="primary"
                    icon="download"
                    disabled={!selectedAds.length || importLoading}
                    loading={importLoading}
                    loadingType="classic"
                    onClick={handleImport}
                  >
                    {t('fb_advantage_plus.ads_importer.imports_ads', { default: 'Import ads' })}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

FbAdsImporter.propTypes = {
  organizationId: PropTypes.number.isRequired,
  campaignSettingId: PropTypes.number.isRequired,
  campaignName: PropTypes.string.isRequired,
  version: PropTypes.number.isRequired,
  setVersion: PropTypes.func.isRequired,
  setHasAdsToImport: PropTypes.func.isRequired,
};

export default FbAdsImporter;
