import React from 'react';
import PropTypes from 'prop-types';
import { t } from '../../i18n';
import { Row, Col, Icon, Text, cssVariables } from '../index';
import { AdPreviewContainer, FbPagePreview } from './index';
import gradientBackground from '../../../../assets/images/fb_ad_preview/fb_stories_gradient_background.png';
import progressBarSingle from '../../../../assets/images/fb_ad_preview/progress_single.png';
import progressBarCarousel from '../../../../assets/images/fb_ad_preview/progress_carousel.png';

const FbStoriesPreview = ({ media, pageImageUrl, pageName, label, primaryText, ctaText, type, creativeSource }) => {
  const imageUrl =
    creativeSource === 'manual_upload'
      ? media.image_asset_attributes?.image_url || media.video_asset_attributes?.image_url
      : media.image_url;
  const isVideo = media.video_asset_attributes;
  return (
    <AdPreviewContainer icon="facebook-square" title={t('fb_ad_preview.facebook', { default: 'Facebook' })}>
      <div className="FbStoriesPreview-container">
        <div
          className="FbStoriesPreview-gradientBackground"
          style={{ background: `url(${gradientBackground})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
        />
        <div
          className="FbStoriesPreview-bluredBackground"
          style={{ background: `url(${imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
        />
        <div className="FbStoriesPreview-header" />
        <div className="FbStoriesPreview-mainHeader">
          <img
            className="FbStoriesPreview-progressBar"
            src={type === 'single' ? progressBarSingle : progressBarCarousel}
            alt="Progress bar"
          />
          <Row padding="s" center justifyBetween>
            <Col shrink>
              <FbPagePreview
                url={pageImageUrl}
                pageName={pageName}
                label={label}
                imageSize="19px"
                nameSize="8px"
                labelSize="6px"
                theme="light"
              />
            </Col>
            <Col shrink>
              <Row center>
                <Icon kind="more" color="#ffffff" size="9px" />
                <Icon kind="close" color="#ffffff" style={{ marginLeft: '15px' }} size="8px" />
              </Row>
            </Col>
          </Row>
        </div>
        <div className="FbStoriesPreview-mainImage">
          <img src={imageUrl} alt={t('fb_ad_preview.facebook_feed_preview', { default: 'Facebook feed preview' })} />
          {isVideo && (
            <div className="AdPreview-playIcon">
              <Icon kind="play" size="15px" color={cssVariables.white} />
            </div>
          )}
        </div>
        <Row justifyCenter className="FbStoriesPreview-primaryText">
          <Text tag="div" bold style={{ fontSize: '7px', color: '#000000' }}>
            {primaryText}
          </Text>
        </Row>
        <div className="FbStoriesPreview-footer">
          <Row className="FbStoriesPreview-footerIconContainer" justifyCenter>
            <Icon kind="chevron-up" color="#ffffff" size="12px" style={{ marginTop: '-3px' }} />
          </Row>
          <Row center justifyCenter style={{ marginTop: 0 }}>
            <Row shrink className="FbStoriesPreview-footerCta" center>
              <Col shrink style={{ marginRight: '2px' }}>
                <Icon kind="anchor" color="#425794" size="13px" />
              </Col>
              <Col>
                <Text semibold style={{ display: 'block', fontSize: '13px', color: '#425794' }}>
                  {ctaText}
                </Text>
              </Col>
            </Row>
          </Row>
        </div>
      </div>
    </AdPreviewContainer>
  );
};

FbStoriesPreview.propTypes = {
  media: PropTypes.object,
  pageImageUrl: PropTypes.string,
  pageName: PropTypes.string,
  label: PropTypes.string,
  primaryText: PropTypes.string,
  ctaText: PropTypes.string,
  type: PropTypes.oneOf(['single', 'carousel']),
};

export default FbStoriesPreview;
