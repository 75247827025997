import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { gql, useQuery } from '@apollo/client';
import { Modal, ModalBody, ModalFooter, Row, Col, cssVariables, Button, Icon } from '../index';
import { t } from '../../i18n';
import { UploadNewMedia, MediaGallery, SourcesMenu } from './index';
import withApiStateHandler from '../withApiStateHandler';
import { SOURCES, MEDIA_TYPES } from './meta';

const GET_MEDIA_QUERY = gql`
  query($organizationId: BigInt!, $campaignSettingId: BigInt!) {
    organization(id: $organizationId) {
      id
      campaignSetting(id: $campaignSettingId) {
        fbAdImages {
          id
          name
          url
          width
          height
        }
        fbAdVideos {
          id
          url
          title
          picture
          length
          width
          height
        }
      }
      images {
        id
        filename
        url
        width
        height
      }
      videos {
        id
        url
        title
        picture
        length
        width
        height
      }
    }
  }
`;

const SelectImageModalContent = withApiStateHandler(
  ({
    refetch,
    media,
    uploadError,
    setUploadError,
    organizationId,
    onSelect,
    campaignSettingId,
    selectedSource,
    selectedMedia,
    setSelectedSource,
  }) => (
    <Row padding="xl">
      <Col width="200px">
        <UploadNewMedia
          organizationId={organizationId}
          campaignSettingId={campaignSettingId}
          key={uploadError}
          onError={setUploadError}
          onImageUploaded={refetch}
        />
        <SourcesMenu sources={SOURCES} selectedSource={selectedSource} setSource={setSelectedSource} />
      </Col>
      <Col grow className="pr-8">
        <MediaGallery
          media={media}
          onSelect={onSelect}
          selectedMedia={selectedMedia}
          selectedSource={selectedSource}
          setUploadError={setUploadError}
          refetch={refetch}
          uploadError={uploadError}
          organizationId={organizationId}
          campaignSettingId={campaignSettingId}
        />
      </Col>
    </Row>
  )
);

SelectImageModalContent.propTypes = {
  minWidth: PropTypes.number,
  minHeight: PropTypes.number,
  media: PropTypes.array,
  onSelect: PropTypes.func,
  refetch: PropTypes.func,
  uploadError: PropTypes.string,
  setUploadError: PropTypes.func,
  selectedSource: PropTypes.string,
  setSelectedSource: PropTypes.func,
  organizationId: PropTypes.number,
};

const AdSelectMediaModal = ({ onClose, onConfirm, confirmButtonText, organizationId, campaignSettingId, ...props }) => {
  const [selectedSource, setSelectedSource] = useState('');
  const [uploadError, setUploadError] = useState('');
  const { loading, data, refetch, error } = useQuery(GET_MEDIA_QUERY, {
    variables: { organizationId, campaignSettingId },
    fetchPolicy: 'network-only',
  });

  const localImages = data?.organization?.images || [];
  const localVideos = data?.organization?.videos || [];
  const fbImages = data?.organization?.campaignSetting?.fbAdImages || [];
  const fbVideos = data?.organization?.campaignSetting?.fbAdVideos || [];

  const localImagesMapped = localImages.map(image => ({
    ...image,
    name: image.filename,
    source: 'Dotidot',
    type: MEDIA_TYPES.IMAGE,
  }));

  const localVideosMapped = localVideos.map(video => ({
    ...video,
    name: video.title,
    url: video.picture,
    videoSource: video.url,
    source: 'Dotidot',
    type: MEDIA_TYPES.VIDEO,
  }));

  const fbImagesMapped = fbImages.map(image => ({
    ...image,
    source: 'Facebook',
    type: MEDIA_TYPES.IMAGE,
  }));

  const videosMapped = fbVideos.map(video => ({
    ...video,
    name: video.title,
    url: video.picture,
    videoSource: video.url,
    source: 'Facebook',
    type: MEDIA_TYPES.VIDEO,
  }));

  const allMedia = [...localVideosMapped, ...videosMapped, ...localImagesMapped, ...fbImagesMapped];
  const allMediaFiltered = allMedia.filter(image => selectedSource === '' || image.source === selectedSource);

  return (
    <Modal
      onClose={onClose}
      size="dynamic"
      heading={
        <Row center>
          <Icon kind="image-widget" size="24px" className="mr-12" color={cssVariables.iconSubtle} />
          {t('media.select_media')}
        </Row>
      }
    >
      <ModalBody classNames={cs({ 'overflow-visible': loading })}>
        <SelectImageModalContent
          {...props}
          refetch={refetch}
          error={error}
          loading={loading}
          media={allMediaFiltered}
          uploadError={uploadError}
          setUploadError={setUploadError}
          selectedSource={selectedSource}
          setSelectedSource={setSelectedSource}
          organizationId={organizationId}
          campaignSettingId={campaignSettingId}
        />
      </ModalBody>

      {allMedia?.length > 0 && !loading && !error && (
        <ModalFooter>
          <Button kind="primary" onClick={onConfirm} disabled={!props.selectedMedia?.id}>
            {confirmButtonText || 'Confirm'}
          </Button>
          <Button kind="secondary" onClick={onClose}>
            {t('media.cancel', { default: 'Cancel' })}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

AdSelectMediaModal.propTypes = {
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  onConfirm: PropTypes.func,
  confirmButtonText: PropTypes.string,
  organizationId: PropTypes.number,
  selectedMedia: PropTypes.object,
};

export default AdSelectMediaModal;
