import { t } from '../../i18n';

export const aspectRatios = [
  {
    aspect: 1,
    label: `1:1 ${t('media.aspect_ratios.aspect_ratio', { default: 'aspect ratio' })}`,
    minWidth: 600,
    minHeight: 600,
    forMediaPlaces: ['feeds'],
    previewSizes: {
      big: {
        width: 349,
        height: 349,
      },
      small: {
        width: 48,
        height: 48,
      },
    },
  },
  {
    aspect: 0.5625,
    label: `9:16 ${t('media.aspect_ratios.aspect_ratio', { default: 'aspect ratio' })}`,
    minWidth: 600,
    minHeight: 600,
    forMediaPlaces: ['stories'],
    previewSizes: {
      big: {
        width: 264,
        height: 470,
      },
      small: {
        width: 27,
        height: 48,
      },
    },
  },
  {
    aspect: 1.91,
    label: `1.91:1 ${t('media.aspect_ratios.aspect_ratio', { default: 'aspect ratio' })}`,
    minWidth: 600,
    minHeight: 600,
    forMediaPlaces: ['right_column'],
    previewSizes: {
      big: {
        width: 349,
        height: 182,
      },
      small: {
        width: 48,
        height: 25,
      },
    },
  },
];

export const defaultAspectRatioOptions = {
  value: 'original',
  label: t('media.aspect_ratios.original', { default: 'Original' }),
};

export const singleMediaPlaces = ['feeds', 'stories', 'right_column'];

export const defaultCropSettings = {
  unit: 'px',
};

export const SOURCES = [
  {
    value: '',
    label: t('media.sources.all', { default: 'All' }),
  },
  {
    value: 'Dotidot',
    label: 'Dotidot',
  },
  {
    value: 'Facebook',
    label: 'Facebook',
  },
];

export const mediaTabs = [
  { value: 'all', label: t('media.tabs.all', { default: 'All' }) },
  { value: 'image', label: t('media.tabs.images', { default: 'Images' }) },
  { value: 'video', label: t('media.tabs.videos', { default: 'Videos' }) },
];

export const PAGE_LIMIT = 10;

export const MEDIA_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
};
