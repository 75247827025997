import React from 'react';

const CroppedImage = ({ media, size }) => {
  const { crop, source } = media;
  return (
    <div
      style={{
        width: `${crop.width}px`,
        height: `${crop.height}px`,
        position: 'relative',
        zoom: crop.width > crop.height ? size.width / crop.width : size.height / crop.height,
        overflow: 'hidden',
      }}
    >
      <img
        alt="preview"
        src={source}
        style={{ left: `${crop.x * -1}px`, top: `${crop.y * -1}px`, position: 'absolute' }}
      />
    </div>
  );
};

export default CroppedImage;
