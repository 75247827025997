import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { DropdownMenu, Link, Icon, cssVariables } from '../index';
import { UploadProgressStatus } from './index';
import {
  uploadFile,
  calculateMD5Base64,
  validateVideoFile,
  createVideoPreview,
  uploadVideoWithProgress,
  validateImageFile,
  createGifPreview,
} from './utils';
import { t } from '../../i18n';
import { MEDIA_TYPES } from './meta';

const UploadNewMedia = ({
  onImageUploaded,
  onError,
  minWidth = 600,
  minHeight = 600,
  organizationId,
  campaignSettingId,
}) => {
  const imageInput = useRef(null);
  const videoInput = useRef(null);
  const [uploadingMediaCount, setUploadingMediaCount] = useState(0);
  const [isUploadSuccessful, setUploadSuccessful] = useState(false);
  const [uploadingMediaType, setUploadingMediaType] = useState(MEDIA_TYPES.IMAGE); // image or video
  const [totalMediaToUploadCount, setTotalMediaToUploadCount] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadingVideoInfo, setUploadingVideoInfo] = useState(null);

  const abortControllerRef = useRef(null);

  useEffect(() => {
    if (isUploadSuccessful) {
      setTimeout(() => {
        setUploadSuccessful(false);
      }, 3000);
    }
  }, [isUploadSuccessful]);

  const [createImage] = useMutation(
    gql`
      mutation CreateImage($organizationId: BigInt!, $filename: String, $imageData: String) {
        createImage(organizationId: $organizationId, filename: $filename, imageData: $imageData) {
          image {
            id
            url
          }
          errors
        }
      }
    `,
    {
      onCompleted: () => {
        setUploadingMediaCount(prev => {
          const newCount = prev - 1;
          if (newCount === 0) {
            setUploadSuccessful(true);
          }
          return newCount;
        });
        onImageUploaded();
        onError('');
      },
      onError: () => {
        onError('server_error');
        setUploadingMediaCount(prev => prev - 1);
      },
    }
  );

  const handleImageInputChange = async event => {
    setUploadingMediaType(MEDIA_TYPES.IMAGE);
    onError('');
    const files = event.target.files;

    if (!files || files.length === 0) {
      return;
    }

    const validFiles = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const file of files) {
      // eslint-disable-next-line no-await-in-loop
      const isValid = await validateImageFile(file, 31457280, minWidth, minHeight);
      if (!isValid) {
        break;
      }

      validFiles.push(file);
    }

    if (validFiles.length !== files.length) {
      onError('image_validation');
      return;
    }

    setTotalMediaToUploadCount(validFiles.length);
    setUploadingMediaCount(validFiles.length);

    validFiles.forEach(file => {
      uploadFile(file, signedId => {
        createImage({
          variables: {
            organizationId,
            filename: file.name,
            imageData: signedId,
          },
        });
      });
    });

    // Reset the input value to ensure the onChange event fires on each file selection
    imageInput.current.value = null;
  };

  const onSuccessVideoUpload = async (_id, _blob_id, videoData, videoFile) => {
    const { width, height, duration } = videoData;
    try {
      let previewFile;
      if (videoFile.type === 'image/gif') {
        previewFile = await createGifPreview(videoFile);
      } else {
        previewFile = await createVideoPreview(videoFile);
      }
      const formData = new FormData();
      formData.append('blob_id', _blob_id);
      formData.append('video[length]', duration);
      formData.append('video[title]', videoFile.name);
      formData.append('video[width]', width);
      formData.append('video[height]', height);
      formData.append('video[thumbnail]', previewFile, previewFile.name);
      const updateResponse = await fetch(
        `${window.location.origin}/organizations/${organizationId}/fb_advantage_plus_campaigns/${campaignSettingId}/videos/${_id}`,
        {
          method: 'PUT',
          body: formData,
        }
      );
      if (updateResponse.ok) {
        setUploadingMediaCount(prev => prev - 1);
        setUploadSuccessful(true);
        onError('');
        onImageUploaded();
      } else {
        setUploadingMediaCount(prev => prev - 1);
        onError('server_error');
      }
    } catch {
      onError('server_error');
    }
  };

  const onErrorVideoUpload = async (_id, _blob_id) => {
    onError('server_error');
    const formData = new FormData();
    formData.append('blob_id', _blob_id);
    try {
      await fetch(
        `${window.location.origin}/organizations/${organizationId}/fb_advantage_plus_campaigns/${campaignSettingId}/videos/${_id}`,
        {
          method: 'DELETE',
          body: formData,
        }
      );
    } catch {
      // Do nothing
    } finally {
      setUploadingMediaCount(prev => prev - 1);
    }
  };

  const handleVideoInputChange = async event => {
    setUploadingMediaType(MEDIA_TYPES.VIDEO);
    onError('');
    const file = event.target.files[0]; // The only file selected

    if (!file) {
      return;
    }

    const videoValidation = await validateVideoFile(file);

    if (videoValidation === 'wrong_type') {
      onError('video_wrong_type');
      return;
    }
    if (!videoValidation) {
      onError('video_validation');
      return;
    }

    setUploadingMediaCount(1);

    const fileChecksum = await calculateMD5Base64(file);

    const presignedUrlUrl = `${window.location.origin}/organizations/${organizationId}/fb_advantage_plus_campaigns/${campaignSettingId}/videos`;
    const presignedUrlRequestBody = {
      filename: file.name,
      content_type: file.type,
      byte_size: file.size,
      checksum: fileChecksum,
    };

    let id;
    let blob_id;

    try {
      const getPresignedUrlResponse = await fetch(presignedUrlUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(presignedUrlRequestBody),
      });

      if (getPresignedUrlResponse.ok) {
        const processedGetPresignedUrlResponse = await getPresignedUrlResponse.json();
        const presigned_url = processedGetPresignedUrlResponse.presigned_url;
        id = processedGetPresignedUrlResponse.id;
        blob_id = processedGetPresignedUrlResponse.blob_id;
        setUploadingVideoInfo({ id, blob_id });
        if (presigned_url) {
          try {
            const { uploadVideoPromise, uploadVideoAbort } = uploadVideoWithProgress(
              presigned_url,
              file,
              fileChecksum,
              setUploadProgress
            );
            abortControllerRef.current = uploadVideoAbort;
            await uploadVideoPromise;
            await onSuccessVideoUpload(id, blob_id, videoValidation, file);
          } catch {
            await onErrorVideoUpload(id, blob_id);
          } finally {
            abortControllerRef.current = null;
          }
        }
      } else {
        onError('server_error');
      }
    } catch (error) {
      onError('server_error');
    }
  };

  const handleAbortUpload = async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current();
      const { id, blob_id } = uploadingVideoInfo;
      await onErrorVideoUpload(id, blob_id);
    }
  };

  return (
    <>
      <input
        style={{ display: 'none' }}
        type="file"
        accept="image/png,image/jpg,image/jpeg"
        ref={imageInput}
        multiple
        onChange={handleImageInputChange}
      />
      <input
        style={{ display: 'none' }}
        type="file"
        accept="video/mp4, video/quicktime, image/gif"
        ref={videoInput}
        onChange={handleVideoInputChange}
      />
      <DropdownMenu
        minWidth="200px"
        primary
        withMarginTop
        withExtraPadding
        disabled={uploadingMediaCount > 0}
        label={t('media_library.upload_media', { default: 'Upload media' })}
        labelIcon={<Icon color={cssVariables.interactivePrimaryOnPrimary} kind="upload" />}
      >
        <Link icon="image-widget" secondary onClick={() => imageInput.current.click()}>
          {t('media_library.upload_images', { default: 'Upload images' })}
        </Link>
        <Link icon="video-play" secondary onClick={() => videoInput.current.click()}>
          {t('media_library.upload_videos', { default: 'Upload videos' })}
        </Link>
      </DropdownMenu>
      <UploadProgressStatus
        totalMediaToUploadCount={totalMediaToUploadCount}
        uploadingMediaType={uploadingMediaType}
        uploadingMediaCount={uploadingMediaCount}
        isUploadSuccessful={isUploadSuccessful}
        uploadProgress={uploadProgress}
        handleAbortUpload={handleAbortUpload}
      />
    </>
  );
};

UploadNewMedia.propTypes = {
  onImageUploaded: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  minWidth: PropTypes.number,
  minHeight: PropTypes.number,
  organizationId: PropTypes.any.isRequired,
};

export default UploadNewMedia;
