import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '../../i18n';
import { Heading } from '../Typography';
import { Button, Col, MediaPreview, Row } from '../index';
import { aspectRatios, MEDIA_TYPES } from './meta';
import { getPreviewSize, getAspectRatioOptions } from './utils';
import { CropImageModal, CropRadioButtons } from './index';

const MediaCard = ({ media, onReplaceButtonClick, onCropMedia, onClearCrop, onDefaultCrop, type }) => {
  const [showCropModal, setShowCropModal] = useState(false);

  const aspectRatioChangeHandler = ratio => {
    if (ratio === 'original') {
      onClearCrop(media);
    } else {
      const selectedAspectRatio = aspectRatios.find(a => a.aspect?.toString() === ratio.toString());
      onDefaultCrop(media, selectedAspectRatio?.aspect);
    }
  };

  const aspectRatioOptions = getAspectRatioOptions(media, type);
  const hasDisabledOptions = aspectRatioOptions.some(option => option.disabled);
  const showCroppingOptions = type === 'single' && media.type === MEDIA_TYPES.IMAGE;
  const showPreviewTitle = type === 'single';
  const showCropButton = media.type === MEDIA_TYPES.IMAGE;

  const aspectRatio = aspectRatios.find(a => a.aspect === media.crop?.aspect);
  const previewSize = getPreviewSize(media, type, 'big');
  const croppingAspect = media.crop?.aspect || (type === 'single' ? aspectRatioOptions[1].value : 'original');
  const disableCropButton = type === 'single' && hasDisabledOptions;

  const onCropMediaHandler = (crop, image) => {
    onCropMedia(crop, image);
    setShowCropModal(false);
  };

  return (
    <>
      <div className="MediaPreviewCard" data-test-id="media-preview-card">
        <div className="MediaPreviewCard-header">
          {showPreviewTitle && (
            <Heading size="lg" tag="h3">
              {t(`media.place_${media.place}`)}
            </Heading>
          )}
          {showCroppingOptions && (
            <div className="mt-16">
              <CropRadioButtons
                name={`media_aspect_ratio_${media.place}`}
                value={aspectRatio?.aspect || 'original'}
                options={aspectRatioOptions}
                onChange={aspectRatioChangeHandler}
              />
            </div>
          )}
        </div>
        <div className="MediaPreviewCard-imagePreviewWrapper">
          <MediaPreview media={media} size={previewSize} />
        </div>
        <Row padding="m" className="MediaPreviewCard-actions">
          {showCropButton && (
            <Col grow>
              <Button
                kind="secondary"
                icon="crop"
                onClick={() => setShowCropModal(true)}
                disabled={disableCropButton}
                data-test-id="crop-image"
              >
                {t('media.crop', { default: 'Crop' })}
              </Button>
            </Col>
          )}
          <Col grow>
            <Button kind="secondary" icon="refresh" onClick={onReplaceButtonClick} data-test-id="replace-image">
              {t('media.replace', { default: 'Replace' })}
            </Button>
          </Col>
        </Row>
      </div>
      {showCropModal && (
        <CropImageModal
          image={media}
          defaultAspect={croppingAspect}
          onConfirm={onCropMediaHandler}
          onClose={() => {
            setShowCropModal(false);
          }}
          type={type}
          showCroppingOptions={type === 'carousel'}
          croppingOptions={aspectRatioOptions}
        />
      )}
    </>
  );
};

MediaCard.propTypes = {
  media: PropTypes.object.isRequired,
  onReplaceButtonClick: PropTypes.func.isRequired,
  onCropMedia: PropTypes.func.isRequired,
  onClearCrop: PropTypes.func.isRequired,
  onDefaultCrop: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['single', 'carousel']).isRequired,
};

export default MediaCard;
