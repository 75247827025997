import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';
import { Button, Row, Col, Text, DragHandle, SimpleInput } from '../index';
import { SortableElement, SortableContainer } from '../Sortable';
import { t } from '../../i18n';
import { MediaThumbnail, MediaPreviewModal, AdSelectMediaModal } from './index';
import { getPreviewSize, buildCarouselMediaFromProps } from './utils';
import arrayMove from '../../utils/arrayMove';
import { MEDIA_TYPES } from './meta';

const MediaCarouselItem = SortableElement(({ mediaItem, ...props }) => {
  const size = getPreviewSize(mediaItem, 'carousel', 'small');
  return (
    <div className="mb-16 MediaCarousel-mediaItem" key={mediaItem.id} data-test-id={props.testId}>
      <Row justifyBetween center>
        <Col shrink>
          <Row center>
            <Col shrink className="MediaCarousel-dragHandlerWrapper">
              <DragHandle />
            </Col>
            <Col shrink>
              <Button
                kind="tertiary"
                icon="chevron-right"
                onlyIcon
                onClick={() => props.onToggleHandler(mediaItem)}
                style={{ transform: mediaItem.open ? 'rotate(90deg)' : 'rotate(0deg)' }}
              />
            </Col>
            <Col shrink>
              <div className="MediaCarousel-mediaPreviewWrapper">
                <MediaThumbnail media={mediaItem} size={size} style={{ borderRadius: '4px' }} />
              </div>
            </Col>
            <Col style={{ maxWidth: '470px' }}>
              <Text tag="div" truncated>
                {mediaItem.name}
              </Text>
            </Col>
          </Row>
        </Col>
        <Col shrink>
          <Row center padding="m">
            <Col shrink style={{ marginRight: '12px' }}>
              <Button
                kind="secondary"
                icon="edit"
                onClick={() => props.onEditHandler(mediaItem)}
                data-test-id="edit-media"
              >
                {t('media.edit', { default: 'Edit' })}
              </Button>
            </Col>
            <Col shrink>
              <Button
                tertiary
                status={'attention'}
                icon="trash"
                onlyIcon
                onClick={() => props.onRemoveHandler(mediaItem)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {mediaItem.open && (
        <div className="MediaCarousel--mediaItem__details" data-test-id="media-details">
          <div className="mt-16 mb-16 delimeter dashed" />
          <Row padding="m">
            <Col shrink className="w-50">
              <SimpleInput
                testId="headline"
                id="headline"
                name="headline"
                value={mediaItem.headline}
                onChange={value => props.onUpdateInputHandler(mediaItem, 'headline', value)}
                label={t('media.headline', { default: 'Headline' })}
              />
            </Col>
          </Row>
          <Row padding="m" style={{ marginTop: '16px' }}>
            <Col shrink className="w-50">
              <SimpleInput
                testId="description"
                id="description"
                name="description"
                value={mediaItem.description}
                onChange={value => props.onUpdateInputHandler(mediaItem, 'description', value)}
                label={t('media.description', { default: 'Description' })}
                tooltip={t('media.description_tooltip', {
                  default:
                    "The description is an additional text that appears in some placements. It's position on the ad varies by placement.",
                })}
              />
            </Col>
          </Row>
          <Row padding="m" style={{ marginTop: '16px' }}>
            <Col shrink className="w-50">
              <SimpleInput
                testId="websiteUrl"
                id="websiteUrl"
                name="websiteUrl"
                value={mediaItem.websiteUrl}
                onChange={value => props.onUpdateInputHandler(mediaItem, 'websiteUrl', value)}
                required
                error={mediaItem?.errors?.url?.[0]}
                label={t('media.website_url', { default: 'Website URL' })}
                tooltip={t('media.website_url_tooltip', {
                  default: 'Enter the URL for the web page you want people to visit.',
                })}
              />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
});

export const MediaCarouselItemList = SortableContainer(({ collection = [], ...props }) => (
  <div>
    {collection.map((el, index) => (
      <MediaCarouselItem {...props} key={el?.id} index={index} mediaItem={el} testId={`media-item-${index + 1}`} />
    ))}
  </div>
));

const AdMediaUploadEditCarousel = ({ options, data }) => {
  const [selectedMedia, setSelectedMedia] = useState({});
  const [media, setMedia] = useState(buildCarouselMediaFromProps(data));
  const [mediaToDelete, setMediaToDelete] = useState([]);
  const [showSelectMediaModal, setShowSelectMediaModal] = useState(false);
  const [showPreviewMediaModal, setShowPreviewMediaModal] = useState(false);
  const [isNew, setIsNew] = useState(false);

  const addMediaSource = () => {
    const newMedia = {
      id: v4(),
      media_id: selectedMedia.id,
      source: selectedMedia.xlinkHref,
      crop: null,
      width: selectedMedia.width,
      height: selectedMedia.height,
      name: selectedMedia.filename,
      open: true,
      headline: '',
      description: '',
      websiteUrl: '',
      type: selectedMedia.type,
      videoSource: selectedMedia.videoSource,
    };

    setSelectedMedia(newMedia);
    setShowSelectMediaModal(false);
    if (selectedMedia.type === MEDIA_TYPES.IMAGE) {
      setTimeout(() => {
        setShowPreviewMediaModal(true);
      }, 500);
    } else {
      setMedia([...media, newMedia]);
    }
  };

  const setMediaSource = mediaFromPreview => {
    const mediaItem = mediaFromPreview[0];
    if (isNew) {
      setMedia([...media, mediaItem]);
    } else {
      setMedia(media.map(m => (m.id === mediaItem.id ? mediaItem : m)));
    }
    setShowPreviewMediaModal(false);
    setSelectedMedia({});
  };

  const removeMediaItem = mediaItem => {
    setMediaToDelete(prevState => [...prevState, ...media.filter(m => m.line_id === mediaItem.line_id)]);
    setMedia(media.filter(m => m.id !== mediaItem.id));
  };

  const updateMediaTextField = (mediaItem, field, value) => {
    setMedia(media.map(m => (m.id === mediaItem.id ? { ...m, [field]: value } : m)));
  };

  const toggleMediaItemDetails = mediaItem => {
    setMedia(media.map(m => (m.id === mediaItem.id ? { ...m, open: !m.open } : m)));
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setMedia(arrayMove([...media], oldIndex, newIndex));
  };

  return (
    <React.Fragment>
      {media.map((mediaItem, i) => {
        const mediaType = mediaItem.type;
        return (
          <React.Fragment key={mediaItem.id}>
            <input
              type="hidden"
              name={`fb_adtext[carousel_adtext_lines_attributes][${i}][id]`}
              value={mediaItem.line_id}
            />
            <input type="hidden" name={`fb_adtext[carousel_adtext_lines_attributes][${i}][position]`} value={i} />
            <input type="hidden" name={`fb_adtext[carousel_adtext_lines_attributes][${i}][place]`} value={'carousel'} />
            <input
              type="hidden"
              name={`fb_adtext[carousel_adtext_lines_attributes][${i}][${mediaType}_asset_attributes][id]`}
              value={mediaItem.asset_id}
            />
            <input
              type="hidden"
              name={`fb_adtext[carousel_adtext_lines_attributes][${i}][${mediaType}_asset_attributes][${mediaType}_id]`}
              value={mediaItem.media_id}
            />
            <input
              type="hidden"
              name={`fb_adtext[carousel_adtext_lines_attributes][${i}][${mediaType}_asset_attributes][image_url]`}
              value={mediaItem.source}
            />
            {mediaType === MEDIA_TYPES.IMAGE && (
              <>
                <input
                  type="hidden"
                  name={`fb_adtext[carousel_adtext_lines_attributes][${i}][image_asset_attributes][aspect]`}
                  value={mediaItem.crop?.aspect}
                />
                <input
                  type="hidden"
                  name={`fb_adtext[carousel_adtext_lines_attributes][${i}][image_asset_attributes][crop_x]`}
                  value={mediaItem.crop?.x}
                />
                <input
                  type="hidden"
                  name={`fb_adtext[carousel_adtext_lines_attributes][${i}][image_asset_attributes][crop_y]`}
                  value={mediaItem.crop?.y}
                />
                <input
                  type="hidden"
                  name={`fb_adtext[carousel_adtext_lines_attributes][${i}][image_asset_attributes][crop_width]`}
                  value={mediaItem.crop?.width}
                />
                <input
                  type="hidden"
                  name={`fb_adtext[carousel_adtext_lines_attributes][${i}][image_asset_attributes][crop_height]`}
                  value={mediaItem.crop?.height}
                />
              </>
            )}
            <input
              type="hidden"
              name={`fb_adtext[carousel_adtext_lines_attributes][${i}][name]`}
              value={mediaItem.headline}
            />
            <input
              type="hidden"
              name={`fb_adtext[carousel_adtext_lines_attributes][${i}][text]`}
              value={mediaItem.description}
            />
            <input
              type="hidden"
              name={`fb_adtext[carousel_adtext_lines_attributes][${i}][url]`}
              value={mediaItem.websiteUrl}
            />
          </React.Fragment>
        );
      })}

      {mediaToDelete.map((mediaItem, i) => (
        <React.Fragment key={mediaItem.id}>
          <input
            type="hidden"
            name={`fb_adtext[carousel_adtext_lines_attributes][${media.length + i}][id]`}
            value={mediaItem.line_id}
          />
          <input
            type="hidden"
            name={`fb_adtext[carousel_adtext_lines_attributes][${media.length + i}][_destroy]`}
            value="true"
          />
        </React.Fragment>
      ))}

      {!!media.length && (
        <div className="mt-16">
          <MediaCarouselItemList
            collection={media}
            onEditHandler={mediaItem => {
              setSelectedMedia(mediaItem);
              setShowPreviewMediaModal(true);
              setIsNew(false);
            }}
            onRemoveHandler={removeMediaItem}
            onUpdateInputHandler={updateMediaTextField}
            onToggleHandler={toggleMediaItemDetails}
            onSortEnd={onSortEnd}
            useDragHandle
            lockAxis="y"
          />
        </div>
      )}

      <Row padding="l" center>
        <Col shrink>
          <Button
            kind="primary"
            icon="plus"
            data-test-id="add-media"
            disabled={media.length > 9}
            onClick={() => {
              setShowSelectMediaModal(true);
              setIsNew(true);
            }}
          >
            {t('media.add_media')}
          </Button>
        </Col>
        <Col shrink>
          <Text color="lightGray">{`${media.length} of 10`}</Text>
        </Col>
      </Row>

      {showSelectMediaModal && (
        <AdSelectMediaModal
          organizationId={options.organization_id}
          campaignSettingId={options.campaign_setting_id}
          selectedMedia={selectedMedia}
          onSelect={setSelectedMedia}
          onConfirm={addMediaSource}
          confirmButtonText={
            selectedMedia?.type !== MEDIA_TYPES.VIDEO
              ? t('media.continue_to_crop', { default: 'Continue to crop' })
              : t('media.continue', { default: 'Continue' })
          }
          onClose={() => {
            setShowSelectMediaModal(false);
          }}
        />
      )}

      {showPreviewMediaModal && (
        <MediaPreviewModal
          media={[selectedMedia]}
          onConfirm={setMediaSource}
          confirmButtonText={
            isNew ? t('media.insert_media', { default: 'Insert media' }) : t('media.save', { default: 'Save' })
          }
          onClose={() => {
            setShowPreviewMediaModal(false);
            setSelectedMedia({});
          }}
          onGoBack={() => {
            setShowPreviewMediaModal(false);
            setTimeout(() => {
              setShowSelectMediaModal(true);
            }, 500);
          }}
          showGoBackButton={false}
          organizationId={options.organization_id}
          campaignSettingId={options.campaign_setting_id}
          type="carousel"
        />
      )}
    </React.Fragment>
  );
};

AdMediaUploadEditCarousel.propTypes = {
  options: PropTypes.any,
  data: PropTypes.any,
};

export default AdMediaUploadEditCarousel;
