import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import uuid from 'uuid';
import { Badge, Icon, Row, Tooltip } from '../index';

export default class SegmentedButton extends Component {
  static propTypes = {
    className: PropTypes.string,
    collection: PropTypes.arrayOf(PropTypes.object).isRequired,
    doNotUseInternalState: PropTypes.bool,
    fullWidth: PropTypes.bool,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onlyIcons: PropTypes.bool,
    required: PropTypes.bool,
    size: PropTypes.oneOf[('small', 'medium', 'large')],
    value: PropTypes.any.isRequired,
  };

  static defaultProps = {
    doNotUseInternalState: false,
    required: false,
    size: 'small',
  };

  state = {
    value: this.props.value,
    id: this.props.id || uuid(),
  };

  handleChange = e => {
    const {
      target: { value },
    } = e;

    this.setState({ value });

    if (typeof this.props.onChange === 'function') {
      this.props.onChange(e);
    }
  };

  render() {
    const {
      value,
      className,
      name,
      required,
      doNotUseInternalState,
      collection,
      size,
      fullWidth,
      onlyIcons,
      ...rest
    } = this.props;
    const { id } = this.state;

    const selected = doNotUseInternalState ? value : this.state.value;

    return (
      <div
        {...rest}
        className={cs(className, 'Segmented', `Segmented--${size}`, 'radio_buttons', {
          'Segmented--onlyIcons': onlyIcons,
          'Segmented--fullWidth': fullWidth,
        })}
      >
        <div className="Segmented-wrapper">
          <input type="hidden" id={id} name={name} value="" />
          {collection.map(
            ({ value: optionValue, label, icon, rightIcon, onlyIconText, tooltipText, badge, badgeType }) => {
              const optionId = `${id}_${optionValue}`;

              return (
                <span className="radio" key={optionId}>
                  <Tooltip text={tooltipText}>
                    <input
                      className={cs('Segmented-input', 'radio_buttons', {
                        required,
                      })}
                      onChange={this.handleChange}
                      checked={selected === optionValue}
                      type="radio"
                      value={optionValue}
                      name={name}
                      id={optionId}
                    />
                    <label
                      className={cs('collection_radio_buttons nowrap', { 'Segmented-label--onlyIcons': onlyIconText })}
                      htmlFor={optionId}
                    >
                      <Row noPadding center justifyCenter>
                        {icon && (
                          <Icon
                            kind={icon}
                            inheritColor
                            className={cs('Segmented-labelIcon', { 'mr-4': !onlyIcons })}
                          />
                        )}
                        {label && !onlyIcons && label}
                        {onlyIcons && onlyIconText && !icon && <span>{label}</span>}
                        {rightIcon && (
                          <Icon
                            kind={rightIcon}
                            inheritColor
                            className={cs('Segmented-labelIcon ml-4', { 'mr-4': badge })}
                          />
                        )}
                        {badge && !onlyIcons && (
                          <Badge className="CustomSwiper-badge ml-4" kind={badgeType || 'blue'} size="medium">
                            {badge}
                          </Badge>
                        )}
                      </Row>
                    </label>
                  </Tooltip>
                </span>
              );
            }
          )}
        </div>
      </div>
    );
  }
}
