import React from 'react';
import { Icon, Col, Row, cssVariables, Loader, Link, Text } from '../index';
import { t } from '../../i18n';
import { MEDIA_TYPES } from './meta';

const UploadProgressStatus = ({
  uploadingMediaType,
  totalMediaToUploadCount,
  uploadingMediaCount,
  isUploadSuccessful,
  uploadProgress,
  handleAbortUpload,
}) => {
  if (uploadingMediaCount === 0 && !isUploadSuccessful) {
    return null;
  }

  return (
    <div
      className="mt-16 mb-0"
      style={{ border: `1px solid ${cssVariables.interfaceOutlineBold}`, borderRadius: '8px' }}
    >
      <Row center style={{ padding: '8px' }}>
        <Col shrink>
          {isUploadSuccessful ? (
            <Icon kind="circle-check" size="24px" color={cssVariables.statusPositiveDefault} />
          ) : (
            <Loader size="small" />
          )}
        </Col>
        <Col shrink>
          <Text medium>
            {isUploadSuccessful
              ? t('media_library.upload_finished', { default: 'Upload finished' })
              : t('media_library.uploading', { default: 'Uploading media...' })}
          </Text>
        </Col>
        <Col shrink style={{ marginLeft: 'auto' }}>
          <Text medium style={{ color: cssVariables.textSubtle }}>
            {uploadingMediaType === MEDIA_TYPES.IMAGE &&
              `${totalMediaToUploadCount - uploadingMediaCount} of ${totalMediaToUploadCount}`}
            {uploadingMediaType === MEDIA_TYPES.VIDEO && `${uploadProgress}%`}
          </Text>
        </Col>
      </Row>
      {uploadingMediaType === MEDIA_TYPES.VIDEO && (
        <Row
          justifyCenter
          style={{ padding: '8px', margin: 0, borderTop: `1px solid ${cssVariables.interfaceOutlineBold}` }}
        >
          <Link size="sm" onClick={handleAbortUpload} disabled={isUploadSuccessful}>
            {t('media_library.cancel_upload', { default: 'Cancel upload' })}
          </Link>
        </Row>
      )}
    </div>
  );
};

export default UploadProgressStatus;
