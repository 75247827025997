import React from 'react';
import { CroppedImage } from './index';
import { cssVariables, Icon } from '../index';
import { MEDIA_TYPES } from './meta';

const MediaThumbnail = ({ media, size, style }) => {
  const { crop, source, type } = media;

  return (
    <div className="MediaThumbnail" style={{ width: size?.width, height: size?.height, ...style }}>
      <div className="MediaThumbnail-content">
        {crop && size ? (
          <CroppedImage media={media} size={size} />
        ) : (
          <img className="MediaThumbnail-imageOriginal" src={source} alt="preview" />
        )}
        {type === MEDIA_TYPES.VIDEO && (
          <div className="MediaThumbnail-videoPlayIcon">
            <Icon kind="play" size="12px" color={cssVariables.white} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MediaThumbnail;
