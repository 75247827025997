/* eslint-disable no-return-assign */
import React, { memo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { gql, useLazyQuery } from '@apollo/client';
import cs from 'classnames';
import Searchbox from './Searchbox';
import DefaultList from './DefaultList';
import { CreateElementButton, Icon, Row, Col, Ellipsis } from '../components';
import { filterItems } from './utils';

const BreadcrumbsDropdown = ({
  bold = true,
  coloredIcon,
  createButton,
  currentItem,
  dataTestId,
  iconType,
  items = [],
  itemType,
  left,
  loaded,
  loading,
  loadItems,
  searchBoxText,
  small,
  withoutSearch = false,
}) => {
  const [isDropdownClosed, setIsDropdownClosed] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const searchBoxRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = e => {
      if (
        !document.querySelector(`[data-component="BreadcrumbsDropdown"][data-json*="${itemType}"]`).contains(e.target)
      ) {
        setIsDropdownClosed(true);
      }

      if (
        e.target?.parentNode?.classList?.contains('js-profile') &&
        e.target.parentNode.parentNode.classList.contains('active')
      ) {
        const navbarBackdrop = document.getElementById('js-navbar-backdrop');
        if (navbarBackdrop) {
          navbarBackdrop.classList.remove('d-none');
        }
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [itemType]);

  useEffect(() => {
    window.attach_tooltips_to_text_overflow();
  });

  useEffect(() => {
    if (!isDropdownClosed) {
      searchBoxRef.current?.focus();
    }
  }, [isDropdownClosed]);

  useEffect(() => {
    if (!loaded && typeof loadItems === 'function') {
      loadItems();
    }
  }, [loaded, loadItems]);

  const handleSearch = ({ target: { value } }) => {
    setSearchValue(value);
  };

  const search = () => {
    const _search = searchValue.trim();
    return _search.length > 0 ? filterItems(items, _search) : items;
  };

  const openDropdown = () => {
    const newIsDropdownClosed = !isDropdownClosed;
    setIsDropdownClosed(newIsDropdownClosed);

    const navbarBackdrop = document.getElementById('js-navbar-backdrop');
    if (navbarBackdrop) {
      navbarBackdrop.classList.toggle('d-none', newIsDropdownClosed);
    }

    if (!loaded && typeof loadItems === 'function') {
      loadItems();
    }
  };

  const filteredItems = search();

  if (!currentItem) {
    return null;
  }

  return (
    <>
      <div className="Navbar-slash vabl">/</div>
      <div className={cs('Navbar-item', 'ph-0', { active: !isDropdownClosed })}>
        <div
          className={cs('Breadcrumbs-dropdownButton js-navbar-dropdown no-user-select', { selected: bold })}
          onClick={openDropdown}
          data-test-id={dataTestId}
        >
          <Row center>
            {currentItem && !currentItem.isNewRecord && !currentItem.isEmpty && (
              <>
                {currentItem.icon && (
                  <Col shrink>
                    <Icon kind={currentItem.icon} inheritColor className="Breadcrumbs-dropdownButton-icon" />
                  </Col>
                )}
                <Col grow>
                  <Ellipsis>{currentItem.name}</Ellipsis>
                </Col>
              </>
            )}
            <Icon kind="chevron-down" inheritColor className="Breadcrumbs-dropdownButton-chevron" />
          </Row>
        </div>

        <div
          className={cs('Breadcrumbs-dropdown', {
            active: !isDropdownClosed,
            'Breadcrumbs-dropdown--left': left,
            'Breadcrumbs-dropdown--small': small,
          })}
        >
          {!withoutSearch && (
            <div className="Breadcrumbs-search pa-12 delimiter">
              <Searchbox
                searchBoxText={searchBoxText || ''}
                searchValue={searchValue}
                setInputRef={ref => (searchBoxRef.current = ref)}
                handleSearch={handleSearch}
              />
            </div>
          )}

          <DefaultList
            coloredIcon={coloredIcon}
            currentOrganizationId={currentItem.id}
            hasActiveSearch={searchValue?.length > 0}
            iconType={iconType}
            listSpacing={withoutSearch ? 6 : 12}
            loaded={loaded}
            loading={loading}
            organizations={filteredItems}
          />

          {createButton?.buttonText && (
            <div className="pa-12 text-center delimiter-top">
              <CreateElementButton hideText={false} {...createButton} className="w-100 text-left" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const QUERY = itemType => gql`
  query($organizationId: BigInt, $dataSourceId: BigInt) {
    collection(
      identifier: "${itemType}",
      limit: 10000,
      page: 0,
      order: "name",
      organizationId: $organizationId
      dataSourceId: $dataSourceId
    ) {
      ${itemType} {
        id
        name
        showLink
        icon
      }
    }
  }
`;

const AsyncBreadcrumbsDropdown = memo(
  ({ itemType, organizationId, dataSourceId, graphql, items, createButton, ...rest }) => {
    if (graphql) {
      const [loadItems, { loading, data, called }] = useLazyQuery(QUERY(itemType), {
        variables: { organizationId, dataSourceId },
      });

      return (
        <BreadcrumbsDropdown
          loading={loading}
          loadItems={called ? () => {} : loadItems}
          items={data && data.collection[itemType]}
          loaded={called && data && data.collection[itemType]}
          itemType={itemType}
          createButton={{ ...createButton, organizationId }}
          {...rest}
        />
      );
    }

    return <BreadcrumbsDropdown items={items && items} itemType={itemType} {...rest} />;
  }
);

AsyncBreadcrumbsDropdown.propTypes = {
  itemType: PropTypes.string.isRequired,
  organizationId: PropTypes.number,
  dataSourceId: PropTypes.number,
  createButton: PropTypes.object,
  items: PropTypes.any,
  graphql: PropTypes.bool,
};

export default AsyncBreadcrumbsDropdown;
