import React, { useState, useRef, useEffect } from 'react';
import { v4 } from 'uuid';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Row, Col, Text, Icon, cssVariables, Button } from '../index';
import mapDataToInputs from './utils';
import { t } from '../../i18n';

const DynamicInput = ({
  name,
  placeholder = '',
  maxItems,
  maxItemLength,
  errorPrefix,
  addButtonText,
  mustBeUnique,
  value,
}) => {
  const [inputs, setInputs] = useState(mapDataToInputs(value));
  const [inputsToDelete, setInputsToDelete] = useState([]);
  const [touched, setTouched] = useState(false);
  const inputRefs = useRef([]);

  useEffect(() => {
    // Clear and initialize refs array
    inputRefs.current = inputRefs.current.slice(0, inputs.length);
  }, [inputs]);

  useEffect(() => {
    if (inputs.length > 0 && touched) {
      inputRefs.current[inputs.length - 1]?.focus();
    }
  }, [inputs.length]);

  const handleAddInput = () => {
    if (inputs.length < maxItems) {
      const newInput = { uuid: v4(), value: '' };
      setInputs([...inputs, newInput]);
      setTouched(true);
    }
  };

  const handleRemoveInput = uuid => {
    const index = inputs.findIndex(input => input.uuid === uuid);
    if (inputs[index].id) {
      setInputsToDelete([...inputsToDelete, inputs[index].id]);
    }
    setInputs(inputs.filter(input => input.uuid !== uuid));
    setTouched(true);
    if (index > 0) {
      setTimeout(() => {
        inputRefs.current[index - 1]?.focus();
      }, 0);
    } else if (index === 0 && inputs.length > 1) {
      setTimeout(() => {
        inputRefs.current[0]?.focus();
      }, 0);
    }
  };

  const handleChange = (uuid, event) => {
    const newInputs = inputs.map(input => {
      if (input.uuid === uuid) {
        return { ...input, value: event.target.value };
      }
      return input;
    });
    setInputs(newInputs);
  };

  const handleClearInput = uuid => {
    const newInputs = inputs.map(input => {
      if (input.uuid === uuid) {
        return { ...input, value: '' };
      }
      return input;
    });
    setInputs(newInputs);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (inputs.length < maxItems) {
        handleAddInput();
      }
    }
  };

  return (
    <>
      {inputs.map((input, i) => (
        <React.Fragment key={input.uuid}>
          {input.id && <input type="hidden" name={`${name}[${i}][id]`} value={input.id} />}
        </React.Fragment>
      ))}
      {inputsToDelete.map((id, i) => (
        <React.Fragment key={id}>
          <input type="hidden" name={`${name}[${inputs.length + i}][id]`} value={id} />
          <input type="hidden" name={`${name}[${inputs.length + i}][_destroy]`} value="true" />
        </React.Fragment>
      ))}
      <div className="DynamicInput-Wrapper">
        {inputs.map((input, i) => {
          let error = false;
          let errorMessage = '';
          if (input.value.length > maxItemLength) {
            error = true;
            errorMessage = t('react.dynamic_input.exceeds_max_length', {
              default: "You've exceeded the character limit",
            });
          }
          if (mustBeUnique && inputs.filter(item => item.value === input.value).length > 1) {
            error = true;
            errorMessage = `${errorPrefix} must be unique`;
          }
          return (
            <div key={input.uuid} className={cs('DynamicInput-Item mb-16', { 'has-error': error })}>
              <div className={cs('DynamicInput-Input', { 'DynamicInput-Input--error': error })}>
                <input
                  ref={el => {
                    inputRefs.current[i] = el;
                  }}
                  name={`${name}[${i}][text]`}
                  type="text"
                  value={input.value}
                  onChange={e => handleChange(input.uuid, e)}
                  onKeyDown={handleKeyDown}
                  placeholder={placeholder}
                />
                <Icon
                  onClick={() => handleClearInput(input.uuid)}
                  kind="close-outline-squared"
                  size="16px"
                  color={cssVariables.iconGhost}
                  className="DynamicInput-ClearIcon"
                />
              </div>
              <Row center justifyBetween style={{ marginTop: '7px' }}>
                <Col grow>
                  {errorMessage && (
                    <Row center padding="s">
                      <Col shrink>
                        <Icon color={cssVariables.statusAttentionDefault} kind="error-circle" size="16px" />
                      </Col>
                      <Col>
                        <Text style={{ color: cssVariables.statusAttentionDefault }}>{errorMessage}</Text>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col shrink style={{ paddingTop: '1px' }}>
                  <Text
                    size="xs"
                    style={{ color: error ? cssVariables.statusAttentionDefault : cssVariables.textSubtle }}
                  >
                    {input.value.length}/{maxItemLength}
                  </Text>
                </Col>
              </Row>
              {i > 0 && (
                <Button
                  className="DynamicInput-DeleteIcon"
                  icon="trash"
                  tertiary
                  status={'attention'}
                  onlyIcon
                  color={cssVariables.interactiveAttentionDefault}
                  onClick={() => handleRemoveInput(input.uuid)}
                />
              )}
            </div>
          );
        })}
        <Row className="mt-16" center>
          <Col shrink style={{ marginRight: '12px' }}>
            <Button
              onClick={handleAddInput}
              kind="secondary"
              size="small"
              icon="plus"
              disabled={inputs.length >= maxItems}
            >
              {addButtonText}
            </Button>
          </Col>
          <Col>
            <Text style={{ color: cssVariables.textSubtle }}>
              {inputs.length} of {maxItems}
            </Text>
          </Col>
        </Row>
      </div>
    </>
  );
};

DynamicInput.propTypes = {
  value: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  maxItems: PropTypes.number.isRequired,
  maxItemLength: PropTypes.number.isRequired,
  errorPrefix: PropTypes.string,
  addButtonText: PropTypes.string,
};

export default DynamicInput;
