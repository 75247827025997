import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row } from '../components';
import { t } from '../i18n';

import { getCurrentNotEmpty, clearAll } from './index';

const HeurekaParamPrefill = props => {
  const notEmptyData = getCurrentNotEmpty(props.fieldData);

  const deleteAll = () => {
    const dataToRemove = clearAll(props.activeFieldData);
    props.setFieldDataState([...dataToRemove]);
  };

  const usedFields = notEmptyData.map(({ value }) => `${value}`.trim());
  const fieldToAdd = Object.keys(props.options.prefill_fields)
    .map(key => ({ param: props.options.prefill_fields[key], value: `_${key}_` }))
    .filter(({ value }) => usedFields.indexOf(value) === -1);

  const addFields = () => props.setFieldDataState(notEmptyData.concat(fieldToAdd));

  if (Object.keys(props.options.prefill_fields).length === 0) return null;

  return (
    <Row justifyBetween>
      <Button secondary data-test-id="autofill-button" onClick={addFields} disabled={fieldToAdd.length === 0}>
        {t('add_all_variable_values_button', { scope: 'remap_variables' })}
      </Button>
      <Button
        tertiary
        status={'attention'}
        icon="trash"
        className="ml-8"
        disabled={props.activeFieldData.length <= 1}
        data-test-id="clear-all-button"
        onClick={deleteAll}
      >
        {t('clear_all_button', { scope: 'remap_variables' })}
      </Button>
    </Row>
  );
};

HeurekaParamPrefill.propTypes = {
  activeFieldData: PropTypes.array,
  fieldData: PropTypes.array,
  options: PropTypes.object,
  setFieldDataState: PropTypes.func,
};

export default HeurekaParamPrefill;
