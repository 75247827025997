/* eslint-disable prefer-spread */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import RPT from 'prop-types';
import cs from 'classnames';
import { createBrowserHistory } from 'history';
import { Button, Row, Tooltip } from './index';
import { t } from '../i18n';

export const PER_OPTIONS = [5, 10, 20];

export const usePagination = defaultOptions => {
  const [pageSize, setPageSize] = useState(defaultOptions?.pageSize || PER_OPTIONS[0]);
  const [currentPage, setCurrentPage] = useState(defaultOptions?.currentPage || 0);
  const [pagInfo, setPagInfo] = useState({ totalItems: defaultOptions?.totalItems || 0 });

  useEffect(() => {
    setCurrentPage(0);
  }, [pageSize]);

  const setPaginationInfo = useCallback(
    params => {
      setPagInfo(prev => ({ ...prev, ...params }));
    },
    [setPagInfo]
  );

  const result = useMemo(
    () => ({
      setPaginationInfo,
      pagination: {
        pageSize,
        setPageSize,
        currentPage,
        setCurrentPage,
        maxPages: Math.floor(pagInfo.totalItems / pageSize),
        total: pagInfo.totalItems,
      },
    }),
    [pageSize, setPageSize, pagInfo, currentPage, setCurrentPage, setPaginationInfo]
  );

  return result;
};

const PAGINATION_VARIANTS = {
  classic: {
    stackCount: 9,
    oneCutSideStackCount: 7,
    twoCutSidesStackCount: 5,
    cutIndex: 5,
    step: 2,
  },
  minified: {
    stackCount: 7,
    oneCutSideStackCount: 5,
    twoCutSidesStackCount: 3,
    cutIndex: 3,
    step: 1,
  },
};

const history = createBrowserHistory();

export const getPageFromUrl = () => {
  if (/page=/.test(window.location.hash)) {
    const page = parseInt((window.location.hash || '').replace('#page=', '') || '0', 10);
    if (page === 0) {
      return 0;
    }
    return page - 1;
  }

  return 0;
};

export const setPageToUrl = page => {
  window.location.hash = `page=${page + 1}`;
};

const ArrowButton = ({ hasIconAfterText = false, kind = 'secondary', text, tooltip, size, ...rest }) => (
  <Tooltip text={!text ? tooltip : ''}>
    <Button size={size} onlyIcon={!text} kind={kind} hasIconAfterText={hasIconAfterText} {...rest}>
      {text}
    </Button>
  </Tooltip>
);

ArrowButton.propTypes = {
  hasIconAfterText: RPT.bool,
  kind: RPT.oneOf(['secondary', 'secondary']),
  text: RPT.string,
  tooltip: RPT.string,
};

class Pagination extends React.PureComponent {
  static propTypes = {
    className: RPT.string,
    current: RPT.number,
    disableAnchor: RPT.bool,
    changePage: RPT.func,
    children: RPT.any,
    isCompact: RPT.bool,
    maxPages: RPT.number,
    variant: RPT.oneOf(['classic', 'minified']),
  };

  componentDidMount() {
    this.unlistenHistory = history.listen(this.resolveCurrentPage);
  }

  compomentWillUnmount() {
    if (this.unlistenHistory) {
      this.unlistenHistory();
    }
  }

  resolveCurrentPage = () => {
    const { current, maxPages } = this.props;

    if (maxPages === 0) {
      return null;
    }

    if (this.props.disableAnchor) {
      return current;
    }

    let currentPage = getPageFromUrl();
    if (maxPages - 1 < currentPage) {
      currentPage = maxPages - 1;
    }

    if (currentPage !== current) {
      if (this.changePageDebounceTimeout) {
        clearTimeout(this.changePageDebounceTimeout);
      }
      this.changePageDebounceTimeout = setTimeout(this.changePageCallback(currentPage, false), 200);
    }

    return currentPage;
  };

  changePageCallback = (wantedPage, overrideUrl = true) => () => {
    if (!this.props.disableAnchor) {
      if (overrideUrl) {
        setPageToUrl(wantedPage);
      }
    }
    if (this.props.current !== wantedPage && typeof this.props.changePage === 'function') {
      this.props.changePage(wantedPage)();
    }
  };

  render() {
    const {
      className,
      disableAnchor,
      changePage,
      children,
      isCompact = false,
      maxPages,
      variant = 'minified',
      ...rest
    } = this.props;
    const currentPage = this.resolveCurrentPage();
    let pages = [];
    let startingCounter = 0;
    let firstMore = false;
    let lastMore = false;
    const pagVars = PAGINATION_VARIANTS[variant];
    const buttonSize = isCompact ? 'small' : 'medium';

    if (maxPages > pagVars.stackCount) {
      pages = Array.apply(null, Array(pagVars.oneCutSideStackCount));
      firstMore = false;
      lastMore = true;

      if (currentPage >= pagVars.cutIndex) {
        firstMore = true;
        pages = Array.apply(null, Array(pagVars.twoCutSidesStackCount));
        startingCounter = currentPage - pagVars.step;

        if (currentPage + pagVars.cutIndex >= maxPages) {
          lastMore = false;
          pages = Array.apply(null, Array(pagVars.oneCutSideStackCount));
          startingCounter = maxPages - pagVars.oneCutSideStackCount;
        }
      }
    } else {
      pages = Array.apply(null, Array(maxPages));
    }

    return (
      <div
        className={cs('Pagination', className, {
          'Pagination--onlyButtons': !children,
          'Pagination--compact': isCompact,
        })}
        {...rest}
      >
        {!!children && (
          <div className="Pagination-items">
            <Row center inline>
              {children}
            </Row>
          </div>
        )}
        {maxPages > 1 && (
          <div className="Pagination-buttons" data-test-id="pagination">
            <ArrowButton
              className="mr-12"
              data-test-id="pagination-first"
              disabled={currentPage === 0}
              icon="chevron-first"
              key="first"
              kind="secondary"
              onClick={this.changePageCallback(0)}
              size={buttonSize}
              tooltip={t('views.pagination.first_page')}
            />
            <ArrowButton
              className={isCompact ? 'mr-16' : 'mr-24'}
              data-test-id="pagination-prev"
              disabled={currentPage === 0}
              icon="chevron-left"
              key="previous"
              kind="secondary"
              onClick={this.changePageCallback(currentPage - 1)}
              size={buttonSize}
              text={isCompact ? '' : t('views.pagination.prev_button', { default: 'Prev' })}
              tooltip={t('views.pagination.previous_page')}
            />
            {firstMore && (
              <React.Fragment>
                <Button
                  className="Pagination-button"
                  data-test-id="pagination-current-1"
                  key="1"
                  onClick={this.changePageCallback(0)}
                  size={buttonSize}
                >
                  1
                </Button>
                <Button key="firstMore" className="Pagination-button notActive" disabled size={buttonSize}>
                  …
                </Button>
              </React.Fragment>
            )}
            {pages.map((_, index) => (
              <Button
                data-test-id={`pagination-current-${startingCounter + index + 1}`}
                key={startingCounter + index + 1}
                className={cs('Pagination-button', { active: currentPage === startingCounter + index })}
                onClick={this.changePageCallback(startingCounter + index)}
                size={buttonSize}
              >
                {startingCounter + index + 1}
              </Button>
            ))}
            {lastMore && (
              <React.Fragment>
                <Button key="lastMore" className="Pagination-button notActive" disabled size={buttonSize}>
                  …
                </Button>
                <Button
                  className="Pagination-button"
                  data-test-id={`pagination-current-${maxPages}`}
                  key={maxPages}
                  onClick={this.changePageCallback(maxPages - 1)}
                  size={buttonSize}
                >
                  {maxPages}
                </Button>
              </React.Fragment>
            )}

            <ArrowButton
              className={isCompact ? 'ml-12' : 'ml-20'}
              data-test-id="pagination-next"
              disabled={currentPage === maxPages - 1}
              rightIcon={!isCompact}
              icon="chevron-right"
              key="next"
              kind="secondary"
              onClick={this.changePageCallback(currentPage + 1)}
              size={buttonSize}
              text={isCompact ? '' : t('views.pagination.next_button', { default: 'Next' })}
              tooltip={t('views.pagination.next_page')}
            />
            <ArrowButton
              className="ml-12"
              data-test-id="pagination-last"
              disabled={currentPage === maxPages - 1}
              icon="chevron-last"
              key="last"
              kind="secondary"
              onClick={this.changePageCallback(maxPages - 1)}
              size={buttonSize}
              tooltip={t('views.pagination.last_page')}
            />
          </div>
        )}
      </div>
    );
  }
}

export default hot(Pagination);
