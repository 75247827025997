import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import { getDefaultCrop, getNaturalCrop, getPreviewCrop } from './utils';
import { Row } from '../layout';
import { Button, cssVariables, ErrorBox, Icon, Modal, ModalBody, ModalFooter } from '../index';
import { t, tHtml } from '../../i18n';
import { defaultCropSettings, aspectRatios } from './meta';
import { CropRadioButtons } from './index';

const CropImageModal = ({ image, defaultAspect, onConfirm, onClose, type, showCroppingOptions, croppingOptions }) => {
  const [aspect, setAspect] = useState(defaultAspect);
  const [currentCrop, setCurrentCrop] = useState(null);

  const initialCrop = image.crop || getDefaultCrop(image, aspect);
  const [crop, setCrop] = useState({
    ...defaultCropSettings,
    ...initialCrop,
  });
  const [cropImage, setCropImage] = useState(null);

  const updateCrop = newCrop => {
    if (!aspectRatio) return;
    setCrop(getNaturalCrop(cropImage, newCrop));
    setCurrentCrop(newCrop);
  };

  const aspectRatio = aspectRatios.find(a => a.aspect.toString() === aspect.toString());
  const badImageSize = aspectRatio && (aspectRatio.minWidth > crop.width || aspectRatio.minHeight > crop.height);
  const modalHeadingText =
    type === 'single' ? `${t('media.crop_image_for')} ${t(`media.place_${image.place}`)}` : t('media.crop_image');

  const aspectRatioChangeHandler = ratio => {
    const newCrop = {
      ...defaultCropSettings,
      ...getDefaultCrop(image, ratio),
    };
    setAspect(ratio);
    setCrop(newCrop);
    setCurrentCrop(null);
  };

  const onCropMediaHandler = () => {
    const newCrop = aspect === 'original' ? null : crop;
    onConfirm(newCrop, image);
  };

  return (
    <Modal
      onClose={onClose}
      heading={
        <Row center>
          <Icon kind="crop" size="24px" className="mr-12" color={cssVariables.iconSubtle} />
          {modalHeadingText}
        </Row>
      }
    >
      <ModalBody>
        {showCroppingOptions && (
          <div style={{ marginTop: '-8px', marginBottom: '16px' }}>
            <CropRadioButtons
              name={'media_aspect_ratio'}
              value={aspect}
              options={croppingOptions}
              onChange={aspectRatioChangeHandler}
            />
          </div>
        )}
        <div style={{ textAlign: 'center' }}>
          <ReactCrop
            src={image.source}
            crop={currentCrop || getPreviewCrop(cropImage, crop)}
            onChange={cropImage ? updateCrop : () => {}}
            onImageLoaded={i => setCropImage(i)}
            keepSelection
            imageStyle={{
              maxWidth: '100%',
              maxHeight: '55vh',
            }}
          />
        </div>
        {badImageSize && (
          <ErrorBox withIcon className="mt-12">
            <span>
              {aspectRatio.minWidth > crop.width &&
                tHtml('image_upload.width_is_smaller', {
                  default: 'Image width <b>%{width}px</b> is smaller than <b>%{minWidth}px</b>. ',
                  width: crop.width,
                  minWidth: aspectRatio.minWidth,
                })}
              {aspectRatio.minHeight > crop.height &&
                tHtml('image_upload.height_is_smaller', {
                  default: 'Image height <b>%{height}px</b> is smaller than <b>%{minHeight}px</b>. ',
                  height: crop.height,
                  minHeight: aspectRatio.minHeight,
                })}
            </span>
          </ErrorBox>
        )}
      </ModalBody>
      <ModalFooter>
        <Button kind="primary" onClick={onCropMediaHandler} disabled={badImageSize}>
          {t('media.apply', { default: 'Apply' })}
        </Button>
        <Button kind="secondary" onClick={onClose}>
          {t('media.cancel', { default: 'Cancel' })}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

CropImageModal.propTypes = {
  image: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CropImageModal;
