import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';
import { hot } from 'react-hot-loader/root';
import { NetworkStatus, gql, useQuery } from '@apollo/client';
import { debounce } from 'lodash';
import { parse, unparse } from 'papaparse';
import PlaceholderInput from '../placeholders/Input';
import { WrapperPropTypes } from './WrapperRenderers';
import {
  Button,
  Col,
  Row,
  Loader,
  SectionHeading,
  Text,
  SegmentedButton,
  Tile,
  UsedByGraph,
  Tooltip,
  InfoBox,
  SmallHeading,
} from '../components';
import { getCurrentNotEmpty, handleRemoveItem, clearAll } from './index';
import { t, formatInteger } from '../i18n';
import useInputState from '../components/useInputState';
import downloadTextFile from '../utils/downloadTextFile';
import WebWorkerEnabler from '../services/workerBuilder';
import WorkerCode from './fieldDataWorker';
import RemapMatch from './RemapMatch';

const MAXIMUM_CHILDREN = 300;

export const REMAP_MATCH = 'remap_match';

const GET_DATA_FIELD_OPTIONS = gql`
  query GET_DATA_FIELD_OPTIONS($field: String!, $dataSourceId: BigInt, $size: Int) {
    dataFieldOptions(field: $field, dataSourceId: $dataSourceId, size: $size) {
      key
      docCount
    }
  }
`;
const exportToCSV = data => {
  const clearedData = getCurrentNotEmpty(data).map(({ what, to }) => ({ what, to }));
  const csvData = unparse(clearedData);
  downloadTextFile(csvData, 'variable_export', 'csv');
};

export const normalizeMatchingKey = key => key?.trim()?.toLowerCase();

const importCSV = (evt, complete) => {
  parse(evt?.target?.files?.[0], {
    complete: csv => {
      if (csv.data[0][0] === 'what' && csv.data[0][1] === 'to') csv.data.shift();
      complete(csv?.data.map(row => ({ what: row[0], to: (row[1] || '').replace(/[\n\r]+/g, ' ') })));
    },
    header: false,
  });

  // eslint-disable-next-line no-param-reassign
  evt.target.value = null;
};

const createHashMap = (arr = [], key) =>
  arr.reduce((acc, curr) => {
    acc[normalizeMatchingKey(curr[key])] = curr;
    return acc;
  }, {});

const recalculateFieldData = ({ workerInstance, ...args }) => {
  const functionId = v4();
  workerInstance.postMessage({ ...args, functionId });
};

const debounceFieldData = debounce(recalculateFieldData, 1000);

const allAutoloaded = (data, activeFieldData) => {
  if (!data) return { disabled: true, tooltip: t('remap_variables.no_data') };
  if (!activeFieldData) return { disabled: false };
  const activeFieldKeys = activeFieldData.map(({ what }) => what);
  const allIncluded = data.every(({ key }) => activeFieldKeys.includes(key));
  return allIncluded ? { disabled: true, tooltip: t('remap_variables.all_added') } : { disabled: false };
};

const ImportButton = ({ handleImport, ...rest }) => {
  const hiddenFileInput = useRef(null);

  return (
    <span>
      <input
        type="file"
        accept=".csv"
        id={'csv_file'}
        className="hidden"
        ref={hiddenFileInput}
        onChange={e => importCSV(e, handleImport)}
      />
      <Button {...rest} icon="download" onClick={() => hiddenFileInput.current.click()}>
        {t('import_button', { scope: 'remap_variables' })}
      </Button>
    </span>
  );
};
ImportButton.propTypes = { handleImport: PropTypes.func };

const ExportButton = ({ onClick, ...rest }) => (
  <Button {...rest} icon="feed-export" onClick={onClick}>
    {t('export_button', { scope: 'remap_variables' })}
  </Button>
);

ExportButton.propTypes = { onClick: PropTypes.func };

const RemapControllLine = ({
  options,
  setFieldDataState,
  activeFieldData,
  additionalState,
  setAdditionalState,
  fieldData,
  formBase,
  disabled,
  field,
  addNewFieldText,
  children,
  connectAddNewFieldsToContainer,
  addNewField,
  getMaxCount,
  getCurrentNotEmptyCount,
  overrideComponent,
  setComputedState,
}) => {
  const workerInstanceRef = useRef();
  const { state } = useInputState({ name: 'modifier[input_text]' });
  const { dataSourceId, organizationId } = options;

  const [totalCount, setTotalCount] = React.useState(0);
  const [unusedCount, setUnusedCout] = React.useState(0);

  const { data, networkStatus } = useQuery(GET_DATA_FIELD_OPTIONS, {
    variables: { dataSourceId, organizationId, field: state.slice(1, -1), size: 100000 },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const loadFieldOptions = () => {
    if (data) {
      const mappingHash = createHashMap(fieldData, 'what');

      const activeMappingHash = createHashMap(activeFieldData, 'what');
      const filtredData = data?.dataFieldOptions
        .filter(item => !activeMappingHash[normalizeMatchingKey(item.key)])
        .map(item =>
          mappingHash[normalizeMatchingKey(item.key)]
            ? { ...item, ...mappingHash[normalizeMatchingKey(item.key)], to: '', _destroy: false }
            : { ...item, what: item.key }
        );
      setFieldDataState([...getCurrentNotEmpty(activeFieldData), ...filtredData]);
    }
  };

  const handleCalculationListener = e => {
    if (e?.data) {
      setTotalCount(e.data._totalCount);
      setUnusedCout(e.data._unusedCount);
      setComputedState(e.data.result);
    }
  };

  useEffect(() => {
    workerInstanceRef.current = new WebWorkerEnabler(WorkerCode);

    workerInstanceRef.current.addEventListener('message', handleCalculationListener, false);

    return () => {
      workerInstanceRef.current.removeEventListener('message', handleCalculationListener);
      workerInstanceRef.current.terminate();
    };
  }, []);

  const workerInstance = workerInstanceRef.current;

  useEffect(() => {
    debounceFieldData({
      workerInstance,
      additionalState,
      fieldData,
      data,
      type: 'computeFieldData',
    });
  }, [fieldData, data, additionalState?.remapVariableType]);

  useEffect(() => {
    setAdditionalState({ ...additionalState, remapVariableType: overrideComponent });
  }, []);

  useEffect(() => {
    if (data?.dataFieldOptions?.length > 0) {
      const suggestions = data.dataFieldOptions.map(({ key, docCount }) => ({ value: key, count: docCount }));
      setAdditionalState({ ...additionalState, suggestions });
    }
  }, [data]);

  const loading = networkStatus !== NetworkStatus.ready;

  const deleteAll = () => {
    const dataToRemove = clearAll(activeFieldData);
    setFieldDataState([...dataToRemove]);
  };

  const handleImport = (csv = []) => {
    setAdditionalState({ ...additionalState, remapVariableType: 'remap_exact' });

    const results = csv.filter(({ what, to }) => (what || '').length > 0 || (to || '').length > 0);
    const mappingHash = createHashMap(activeFieldData, 'what');

    const newFieldData = getCurrentNotEmpty(
      results.map(item => ({ ...(mappingHash[normalizeMatchingKey(item.what)] || {}), ...item }))
    );

    const dataToRemove = getCurrentNotEmpty(activeFieldData).reduce((acc, curr) => {
      if (newFieldData.find(({ id }) => curr.id === id)) return acc;

      return [...acc, ...handleRemoveItem([curr], curr.id)];
    }, []);

    setFieldDataState([...dataToRemove, ...newFieldData]);
  };

  const autoload = allAutoloaded(data?.dataFieldOptions, activeFieldData);

  return (
    <Col>
      <Row>
        <Col grow data-test-id="remap_variables-heading">
          <SectionHeading spacing={8}>{t('tile_heading', { scope: 'remap_variables' })}</SectionHeading>
        </Col>

        <Col shrink>
          <Row shrink>
            <ExportButton
              onClick={() => exportToCSV(activeFieldData)}
              size="small"
              secondary
              disabled={loading}
              data-test-id="export-to-csv-button"
            />
            <ImportButton
              handleImport={handleImport}
              size="small"
              className="mh-8"
              secondary
              data-test-id="upload-button"
            />

            <Button
              size="small"
              tertiary
              status={'attention'}
              icon="trash"
              disabled={loading}
              data-test-id="clear-all-button"
              onClick={deleteAll}
            >
              {t('clear_all_button', { scope: 'remap_variables' })}
            </Button>
          </Row>
        </Col>
      </Row>
      <Row center className="mb-24" data-test-id="control-line">
        <Text>{t('rename_content_which', { scope: 'remap_variables' })}</Text>
        <SegmentedButton
          name={`${formBase}[value_type]`}
          id="variables-segmented-value_type"
          className="mh-8"
          key={`segmented-button${additionalState?.remapVariableType}`}
          value={additionalState?.remapVariableType || options.value_type}
          onChange={e => setAdditionalState({ ...additionalState, remapVariableType: e.target.value })}
          collection={[
            { value: 'remap_exact', label: t('segmented_equals', { scope: 'remap_variables' }) },
            { value: REMAP_MATCH, label: t('segmented_contains', { scope: 'remap_variables' }) },
          ]}
        />
        <Text>
          {t((additionalState?.remapVariableType || options.value_type) === REMAP_MATCH ? 'contains' : 'equals', {
            scope: 'remap_variables.entered_value',
          })}
        </Text>
      </Row>
      <Col id={field} setRef={connectAddNewFieldsToContainer}>
        {MAXIMUM_CHILDREN > fieldData.length ? (
          children
        ) : (
          <React.Fragment>
            <InfoBox type="info" className="mb-8" withIcon isComplex>
              <div>{t('information', { scope: 'remap_variables.limit_exceeded', limit: MAXIMUM_CHILDREN })}</div>
              <Row className="mt-4">
                <Col shrink>
                  <ExportButton
                    onClick={() => exportToCSV(activeFieldData)}
                    kind="blue"
                    disabled={loading}
                    data-test-id="export-to-csv-button_too-many-remaps"
                  />
                </Col>
                <Col shrink>
                  <ImportButton handleImport={handleImport} action data-test-id="upload-button_too-many-remaps" />
                </Col>
              </Row>
              <input
                type="hidden"
                name={`${formBase}[replaces_attributes_json]`}
                value={JSON.stringify(
                  fieldData.map(({ persisted, _destroy, id, what, to, position }) =>
                    persisted
                      ? { id, _destroy, what, to, position, replace_type: 'text' }
                      : { what, to, position, replace_type: 'text', _destroy: false }
                  )
                )}
              />
            </InfoBox>

            <SmallHeading className="mt-8">
              {t('remap_variables.limit_exceeded_heading', { count: formatInteger(fieldData?.length) })}
            </SmallHeading>
            <fieldset disabled>
              {fieldData.slice(0, 10).map(d => (
                <RemapMatch
                  {...d}
                  key={d.id}
                  onRemove={() => {}}
                  getSubFiledOptions={() => ({ name: 'disabled_field_remap', id: 'disabled_field_remap_id' })}
                  updateFieldData={() => {}}
                />
              ))}
            </fieldset>
          </React.Fragment>
        )}
        <Row shrink className="mt-8">
          {MAXIMUM_CHILDREN > fieldData.length && (
            <Col shrink>
              <Button
                className={`js-add-field ${options.add_button_class}`}
                disabled={disabled || !addNewField}
                icon={!addNewFieldText ? 'plus' : undefined}
                onClick={() => addNewField({})}
                secondary
                data-test-id={`add-${field}-button`}
                type="button"
                onlyIcon={!addNewFieldText}
              >
                {addNewFieldText}
              </Button>
            </Col>
          )}
          {getMaxCount() > 0 && (
            <Col shrink>
              (
              <span className="pl-24 pt-8 Text--gray">
                {getCurrentNotEmptyCount()} / {getMaxCount()}
              </span>
              )
            </Col>
          )}
          <Col shrink>
            <Tooltip text={autoload?.tooltip}>
              <Button
                secondary
                disabled={loading || autoload.disabled}
                data-test-id="autofill-button"
                onClick={loadFieldOptions}
              >
                {t('add_all_variable_values_button', { scope: 'remap_variables' })}
                {loading && <Loader size="small" />}
              </Button>
            </Tooltip>
          </Col>
        </Row>
        <Row className="mt-16">
          <Tile contentStyle={{ padding: '8px 16px' }} style={{ maxWidth: '800px' }} data-test-id="rest-of-values">
            <Row center>
              <Col shrink data-test-id="graph">
                <UsedByGraph size="md" products={unusedCount || 0} productsTotal={totalCount || 1} />
              </Col>
              <Col grow>
                <Text>{t('rename_rest_of_values_to', { scope: 'remap_variables' })}</Text>
              </Col>
              {additionalState?.remapVariableType === 'remap_match' && <Col width="25px" />}
              <Col grow>
                <PlaceholderInput
                  defaultValue={options.append_text || ''}
                  placeholder={t('views.replaces.fields.new_value')}
                  name={`${formBase}[append_text]`}
                  id="variables-segmented-append_text"
                />
              </Col>
              <Col width="32px" />
            </Row>
          </Tile>
        </Row>
      </Col>
    </Col>
  );
};

RemapControllLine.propTypes = {
  ...WrapperPropTypes,
  addNewField: PropTypes.func.isRequired,
  addNewFieldText: PropTypes.string,
  disabled: PropTypes.bool,
  activeFieldData: PropTypes.array.isRequired,
  fieldData: PropTypes.array,
  setFieldDataState: PropTypes.func.isRequired,
};

RemapControllLine.maximumChildren = MAXIMUM_CHILDREN;
RemapControllLine.computedState = true;

export default hot(RemapControllLine);
