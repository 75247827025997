import React from 'react';
import { CroppedImage } from './index';
import { MEDIA_TYPES } from './meta';

const MediaPreview = ({ media, size, style }) => {
  const { crop, source, type, videoSource } = media;

  const mediaPreview = () => {
    if (crop && size) {
      return <CroppedImage media={media} size={size} />;
    }
    if (type === MEDIA_TYPES.IMAGE) {
      return <img className="MediaPreviewCard-imageOriginal" src={source} alt="preview" />;
    }
    if (type === MEDIA_TYPES.VIDEO) {
      return media.videoSource?.includes('.gif') ? (
        <img src={media.videoSource} alt={media.name} style={{ display: 'block', width: '100%', maxHeight: '100%' }} />
      ) : (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
          key={videoSource} // Force re-render when videoSource changes
          controls
          style={{ display: 'block', width: '100%', maxHeight: '100%' }}
        >
          <source src={videoSource} />
        </video>
      );
    }
    return null;
  };

  return (
    <div
      className="MediaPreviewCard-imagePreviewContainer"
      style={{ width: size?.width, height: size?.height, ...style }}
    >
      <div className="MediaPreviewCard-imagePreviewContent">{mediaPreview()}</div>
    </div>
  );
};

export default MediaPreview;
